import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Taxes_FAQ() {
  return (
    <div className='p-10 text-4xl'>
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #D7A233; /* Bronze color */
            border: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #D7A233; /* Bronze color */
            border-bottom: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #D7A233; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What tax services do you offer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We offer comprehensive tax planning, preparation, and filing services for both individuals and businesses, tailored to meet your specific needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do you ensure compliance with tax laws?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our team of tax professionals stays updated with the latest tax laws and regulations, ensuring that all filings are accurate and compliant with local and federal requirements.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Can you help with tax planning?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we provide strategic tax planning services to help you minimize your tax liability and maximize deductions, tailored to your unique financial situation.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>Do you offer support for business taxes?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Absolutely, we offer a full range of business tax services, including corporate tax planning, filing, and compliance, as well as advice on managing tax liabilities.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>What is your process for tax preparation?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our tax preparation process involves a thorough review of your financial records, identification of applicable deductions and credits, and precise filing to ensure accuracy and compliance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>How can I get started with your tax services?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To get started, simply contact us to schedule a consultation. We will discuss your tax needs and develop a customized plan to help you achieve your financial goals.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
