import React from "react";
import { Helmet } from "react-helmet";
import "aos/dist/aos.css";
import Accordion from "./AppAccordion";
import ContactButton from "./ContactButton";
import Testimonals from "./pages/Testimonals";
import Particless from "./Particless";
import Soft_img_slides from "./pages/software/Soft_img_slides";
import igb from "../images/igb.png";
import byte from "../videos/app.gif";

function Events() {
  return (
    <div className="">
      <Helmet>
        <title>Mobile App Development Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte provides expert mobile app development services, creating robust, user-friendly apps for iOS, Android, and web platforms. Transform your ideas into successful apps."
        />
        <meta
          name="keywords"
          content="mobile app development, iOS app development, Android app development, web applications, app developers, Ironglass Byte"
        />
        <meta property="og:title" content="Mobile App Development Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Transform your ideas into successful mobile apps with Ironglass Byte. Our app development services cover iOS, Android, and web platforms."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Events"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/IronGlass_Byte-logo.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Events" />
      </Helmet>

      <Particless />

      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl mb-5 flex lg:text-6xl text-blue2  p-2 lg:p-4 m-2 font-bold">
              Mobile Apps
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-3xl card p-2 m-2 lg:m-4 border border-[#34b2fd]">
                Ironglass Byte offers comprehensive App development services to
                bring your ideas to life on mobile and web platforms. We create
                user-friendly, robust, and scalable applications tailored to
                meet your specific requirements. Our team of expert developers
                uses cutting-edge technologies to ensure your app delivers a
                seamless experience across all devices.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={igb}
                alt="Ironglass Byte Logo"
              />
            </div>
          </div>

          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 mb-4 lg:mb-0 lg:w-[100rem]">
              <img
                className="w-full h-auto"
                src={byte}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>
            <div className="w-full lg:w-[98%]">
              <p className="text-xl lg:text-2xl card bg-black border border-[#34b2fd] rounded-xl p-2 lg:p-4 m-2">
                We handle the entire app development process, from initial
                concept and design to development, testing, and deployment.
                Whether you need a native app for iOS or Android, or a
                cross-platform solution, we have the expertise to deliver. At
                Ironglass Byte, we focus on creating apps that are not only
                functional but also engaging and easy to use, helping you reach
                and retain more users.
              </p>
            </div>
          </div>

          <div className="text-center lg:h-auto text-xl lg:text-center header p-2 lg:p-4 md:p-0 mb-6">
            <h1 className="text-center text-blue2 font-extrabold m-2 p-2 text-4xl lg:text-5xl">
              Our Work Profiles
            </h1>

            <Soft_img_slides />
          </div>

          <Testimonals />
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl">
              Frequently Asked Questions By Our Clients
            </h1>
            <Accordion />
            <br />
            <br />
          </div>

          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl">
            Contact us to build Apps that scale your business!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
