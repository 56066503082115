import React from "react";
import { Helmet } from "react-helmet";
import ContactButton from "../../ContactButton.js";
import Testimonals from "../Testimonals.js";
import "../../pages.css";
import Particless from "./Law_Particles.js";
import law from "../../../videos/discovery.gif";
import Law from "../../../images/igl.png";
import Discovery_FAQ from "./Discovery_FAQ.js";

const Discovery = () => {
  return (
    <div className="">
      
      {/* SEO Metadata */}
      <Helmet>
        <title>Discovery Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers thorough Discovery services, ensuring that no detail is overlooked. We gather, analyze, and present all critical information relevant to your legal case."
        />
        <meta
          name="keywords"
          content="Discovery services, legal discovery, evidence gathering, legal case analysis, Ironglass Byte, legal investigation, document collection"
        />
        <meta property="og:title" content="Discovery Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Utilize Ironglass Byte's Discovery services to gather and analyze critical case evidence. We ensure no detail is overlooked with our comprehensive approach."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Discovery"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Law_icon_stylized_v004.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Discovery" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        {/* Top Content */}
        <div className="block ">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl flex mb-5 text-[#9c682b] p-2 lg:p-4 m-2 font-bold ">
              Discovery
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#c8944f] rounded-xl bg-black">
                At Ironglass Law, our Discovery service is dedicated to
                meticulously gathering, analyzing, and presenting all relevant
                information crucial to your case. We employ advanced legal
                technologies and thorough investigative techniques to ensure
                that no detail is overlooked, giving you a comprehensive
                understanding of the facts. Our team of seasoned attorneys and
                legal professionals works tirelessly to uncover and organize
                evidence, depositions, and documents.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={Law}
                alt="Ironglass Law Logo"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img
                className="w-full h-auto"
                src={law}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#c8944f] rounded-xl p-2 lg:p-4 m-2">
                At Ironglass Law, we understand the critical importance of
                discovery in the legal process. That's why we invest heavily in
                the latest technology and continuous training for our staff. Our
                thorough and methodical approach ensures that we leave no stone
                unturned, uncovering hidden details that could be pivotal to
                your case. We also prioritize transparency and communication,
                keeping you informed every step of the way.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#9c682b]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Discovery_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#9c682b]">
            Contact us to streamline your legal Discovery needs!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discovery;
