import React from "react";
import { Helmet } from "react-helmet";
import growth_icon from "../../../images/igg.png";
import ContactButton from "../../ContactButton";
import Particless from "./Growth_Particles";
import Testimonals from "../Testimonals";
import SEO_FAQ from "./SEO_FAQ";
import Growth from "../../../videos/seo-.gif";

const SEO = () => {
  return (
    <div className="">

      {/* SEO Metadata */}
      <Helmet>
        <title>SEO Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers expert SEO services to increase your online visibility and drive organic traffic. Our strategies include keyword research, on-page optimization, and link building to enhance your search rankings."
        />
        <meta
          name="keywords"
          content="SEO services, keyword research, on-page SEO, link building, search engine optimization, organic traffic, Ironglass Byte, online visibility"
        />
        <meta property="og:title" content="SEO Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Boost your website's search engine rankings with Ironglass Byte's comprehensive SEO services, including on-page optimization, keyword research, and link building."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/SEO"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Growth_icon_stylized_v003.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/SEO" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl text-[#C0C0C0] mb-5 flex p-2 lg:p-4 m-2 font-bold">
              SEO
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#3bb24d] rounded-xl bg-black">
                Ironglass Growth offers top-notch SEO services to elevate your
                online visibility and drive organic traffic to your website. Our SEO
                experts conduct thorough keyword research, on-page optimization, and
                link-building strategies tailored to your industry and target
                audience. By improving your search engine rankings, we help you
                attract more qualified leads and enhance your online presence. Our
                team stays updated with the latest SEO trends and algorithm changes
                to ensure your website remains competitive.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={growth_icon}
                alt="Ironglass Growth Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={Growth} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#3bb24d] rounded-xl p-2 lg:p-4 m-2">
                Our SEO service is designed to deliver long-term results, providing
                ongoing analysis and adjustments to maintain and improve your
                rankings. We focus on creating high-quality, relevant content that
                resonates with your audience and search engines alike. At Ironglass
                Growth, we understand the importance of a robust SEO strategy in
                today’s digital landscape. Trust us to enhance your online
                visibility, increase website traffic, and ultimately, drive business
                growth through effective SEO practices.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#C0C0C0]">
              Frequently Asked Questions By Our Clients
            </h1>
            <SEO_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#C0C0C0]">
            Contact us to boost your search rankings with SEO strategies!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SEO;
