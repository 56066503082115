import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Production_FAQ() {
  return (
    <div className='p-10 text-4xl'>
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #9c2525; /* Bronze color */
            border: 1px solid #9c2525; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #C0C0C0; /* Bronze color */
            border-bottom: 1px solid #9c2525; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #9c2525; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What does audio production involve?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Audio production involves the entire process of creating a track, from songwriting and arranging to recording, mixing, and final production.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do you ensure the production matches my artistic vision?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We work closely with you throughout the process, ensuring your ideas and preferences are incorporated into the final production to reflect your unique style.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>What genres do you specialize in?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our team is experienced in producing a wide range of genres, including pop, rock, hip-hop, electronic, classical, and more, tailored to your needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What is the role of a producer in the production process?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A producer oversees the entire production process, providing creative direction, technical expertise, and coordination to bring your musical vision to life.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How long does the production process take?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The duration of the production process varies depending on the project's complexity, but we strive to deliver high-quality results within a reasonable timeframe.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Do you provide session musicians if needed?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we have a network of talented session musicians available to enhance your recordings and add the perfect touch to your production.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
