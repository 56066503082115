import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import audio from "../../images/igp.png";
import finance from "../../images/igf.png";
import legal from "../../images/igl.png";
import Investment from "../../images/igi.png";
import growth from "../../images/igg.png";
import Software from "../../images/igb.png";
import "./button.css"; // Import the CSS file
import Myparticle from "../Particless";
import growth_video from "../../images/gh.png";
import software_video from "../../images/sh.png";
import law_video from "../../images/lh.png";
import audio_video from "../../images/ah.png";
import finance_video from "../../images/fh.png";
import investmentVideo from "../../images/ih.png";

const Services = () => {
  const [hoveredButton, setHoveredButton] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (buttonId) => () => {
    setHoveredButton(buttonId);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleClick = (buttonId, to) => (evt) => {
    evt.preventDefault();
    if (buttonId === "investment") {
      window.open(to, "_blank"); // Open the investment link in a new tab
    } else {
      navigate(to); // Navigate to other pages
    }
  };

  const renderButton = (
    to,
    imgSrc,
    imgClass,
    buttonId,
    summary,
    subServices,
    videoSrc,
    color
  ) => (
    <div
      key={buttonId}
      className={`btn bubble1 mx-6 p-8`} // Increased space between bubbles
      onMouseEnter={handleMouseEnter(buttonId)}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick(buttonId, to)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textDecoration: "none",
      }}
    >
      <img
        src={imgSrc}
        className={`${imgClass} h-  mt-[30px]`}
        alt="Service Icon"
      />
      {hoveredButton === buttonId && (
        <div className="fullscreen-popup" style={{ color: color }}>
          <img src={imgSrc} className="popup-logo" alt="Service Logo" />
          <ul className="sub-services">
            {subServices.map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
          <img className="popup-video my-[-10] w-72" src={videoSrc} />
          <h2 className="summary">{summary}</h2>
        </div>
      )}
    </div>
  );

  return (
    <div className={`services-container`}>
      <Myparticle />
      <div className="grid w-screen  mt-[2rem]">
        <div
          className="flex justify-center slide-right"
          style={{ gap: "3rem" }} // Increased gap between the rows
        >
          {renderButton(
            "/Growth_page",
            growth,
            "h-80",
            "growth",
            "Helping businesses grow through strategic marketing and sales solutions.",
            [
              "SEO",
              "Content Marketing",
              "Sales ",
              "Ad Marketing",
              "HR Payrolls",
              "Staffing",
            ],
            growth_video,
            "#62fa79" // Color for Growth
          )}
          {renderButton(
            "/Software_page",
            Software,
            "h-80",
            "software",
            "Innovative software solutions, from web development to AI and blockchain technology.",
            [
              "Web Development",
              "AI ",
              "Blockchain ",
              "UIUX Design",
              "Mobile Apps",
              "Hardware Prototype",
              "Cyber Security",
            ],
            software_video,
            "#0380ca" // Color for Software
          )}
          {renderButton(
            "https://ironglassinvestment.com/#/LearnMore",
            Investment,
            "h-80",
            "investment",
            "Investment strategies and opportunities designed to maximize your returns.",
            [" Crypto Portfolio Management"],
            investmentVideo,
            "gold" // Color for Investment
          )}
        </div>

        <div
          className="flex justify-center slide-left"
          style={{ gap: "3rem", marginTop: "2rem" }} // Increased gap between the rows
        >
          {renderButton(
            "/Legal_page",
            legal,
            "h-80",
            "legal",
            "Expert legal services including patents, entity setup, and litigations.",
            [
              "Patents",
              "Entity Setup",
              "Litigations",
              "Legal Documents",
              "Entity Setup",
            ],
            law_video,
            "#665037" // Color for Legal
          )}
          {renderButton(
            "/Audio_page",
            audio,
            "h-80",
            "audio",
            "Professional audio production, mixing, and mastering services.",
            ["Mixing", "Mastering", "Music Production"],
            audio_video,
            "#C0C0C0" // Color for Audio
          )}
          {renderButton(
            "/Finance_page",
            finance,
            "h-80",
            "finance",
            "Comprehensive financial services including accounts and taxes.",
            ["Taxes", "Accounts Management"],
            finance_video,
            "#F4C32C" // Color for Finance
          )}
        </div>
      </div>
      <div className="h-36"></div>
    </div>
  );
};

export default Services;
