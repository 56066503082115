import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import software from "../../images/software.png";
import "./button.css"; // Import the CSS file
import Particless from "../Particless";

const SoftwarePage = () => {
  const [animatedButtons, setAnimatedButtons] = useState({});
  const navigate = useNavigate();

  const handleClick = (buttonId, to) => (evt) => {
    evt.preventDefault();
    setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: true }));
    setTimeout(() => {
      setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: false }));
      navigate(to); // Navigate to the new page after the animation
    }, 1000);
  };

  const renderButton = (to, imgSrc, buttonId, label) => (
    <Link
      to={to}
      className={`btn bubble1 mx-3 p-10 text-xl bg-black blue2-border hover-item-sm orbitron ${
        animatedButtons[buttonId] ? "animated" : ""
      }`}
      onClick={handleClick(buttonId, to)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textDecoration: "none",
      }}
    >
      <img src={imgSrc} className=" mt-[-25px]" alt={label} /> {/* Adjust height here */}
      {label && <h1 className="text-[#C0C0C0] text-l mt-2">{label}</h1>}
    </Link>
  );

  return (
    <div className=" w-screen mt-[2rem]">
      <Particless />
      <div className="grid">
        <div className="flex justify-center slide-right" style={{ gap: '2rem' }}>
          {renderButton("/Dapps", software, "blockchain", "Blockchain")}
          {renderButton("/Cyber_Security", software, "cyber_security", "Cyber Security")}
          {renderButton("/Websites", software, "websites", "Websites")}
        </div>

        <div className="flex justify-center slide-left" style={{ gap: '2rem', marginTop: '1rem' }}>
          {renderButton("/Apps", software, "apps", "Apps")}
          {renderButton("/ArtificialIntelligence", software, "ai", "Artificial Intelligence")}
          {renderButton("/UIUX", software, "uiux", "UI/UX")}
          {renderButton("/Hardware_Prototype", software, "hardware_prototype", "Hardware Prototype")}
        </div>
      </div>

      <div className="h-36"></div>
    </div>
  );
};

export default SoftwarePage;
