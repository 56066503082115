import React from "react";
import { Helmet } from "react-helmet";
import growth_icon from "../../../images/igg.png";
import ContactButton from "../../ContactButton";
import Testimonals from "../Testimonals";
import Particless from "./Growth_Particles";
import Content_Marketing_FAQ from "./Content_Marketing_FAQ";
import Growth from "../../../videos/content.gif";

const Content_Market = () => {
  return (
    <div className="">
      {/* SEO Metadata */}
      <Helmet>
        <title>Content Marketing Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte's Content Marketing services focus on creating valuable and engaging content to attract and retain your target audience. Boost your brand's authority with our comprehensive content strategies."
        />
        <meta
          name="keywords"
          content="Content Marketing, content strategy, blog posts, videos, infographics, Ironglass Byte, content creation, brand authority"
        />
        <meta property="og:title" content="Content Marketing Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Boost your brand's authority and engage your audience with Ironglass Byte's Content Marketing services. We create high-quality content tailored to your business objectives."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/ContentMarketing"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Growth_icon_stylized_v003.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/ContentMarketing" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl flex mb-5  text-[#C0C0C0] p-2 lg:p-4 m-2 font-bold ">
              Content Marketing
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#3bb24d] rounded-xl bg-black">
                At Ironglass Growth, our Content Marketing service is designed to
                create and distribute valuable, relevant content that attracts and
                engages your target audience. We develop comprehensive content
                strategies that align with your business objectives and resonate
                with your audience. Our team of content creators produces
                high-quality blog posts, articles, videos, infographics, and more to
                build your brand’s authority and trust. By delivering consistent,
                valuable content, we help you attract and retain a loyal customer
                base.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={growth_icon}
                alt="Ironglass Growth Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img
                className="w-full h-auto"
                src={Growth}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#3bb24d] rounded-xl p-2 lg:p-4 m-2">
                Our content marketing approach focuses on understanding your
                audience’s needs and preferences, creating content that addresses
                their pain points and interests. We use analytics and insights to
                measure the effectiveness of our content and make data-driven
                improvements. At Ironglass Growth, we believe that compelling
                content is key to building strong customer relationships and driving
                business growth. Trust us to provide the expertise and creativity
                needed to elevate your content marketing efforts.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#C0C0C0]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Content_Marketing_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#C0C0C0]">
            Contact us to elevate your brand with top-notch Content Marketing!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content_Market;
