import React from "react";
import { Helmet } from "react-helmet";
import "aos/dist/aos.css";
import Accordion from "./AIAccordion";
import ContactButton from "./ContactButton";
import Testimonals from "./pages/Testimonals.js";
import igb from "../images/igb.png";
import Particless from "./Particless.js";
import byte from "../videos/ai.gif";
import Soft_img_slides from "./pages/software/Soft_img_slides.js";

function Events() {
  return (
    <div className="">
      <Helmet>
        <title>Artificial Intelligence Solutions | Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte provides AI services including machine learning, natural language processing, and predictive analytics. Drive innovation with customized AI solutions for your business."
        />
        <meta
          name="keywords"
          content="Artificial Intelligence services, AI solutions, machine learning, natural language processing, predictive analytics, AI development, Ironglass Byte"
        />
        <meta property="og:title" content="Artificial Intelligence Solutions | Ironglass Byte" />
        <meta
          property="og:description"
          content="Leverage the power of Artificial Intelligence with Ironglass Byte. Our AI services include machine learning, predictive analytics, and more to drive business innovation."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Artificial-Intelligence"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/IronGlass_Byte-logo.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Artificial-Intelligence" />
      </Helmet>

      <Particless />

      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl mb-5 flex lg:text-6xl text-blue2  p-2 lg:p-4 m-2 font-bold">
              Artificial Intelligence
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#34b2fd]">
                At Ironglass Byte, our Artificial Intelligence services enable businesses to leverage the power of AI to drive innovation and efficiency. We offer AI solutions such as machine learning, natural language processing, and predictive analytics that can be integrated into your existing systems. Our AI experts work closely with you to understand your business challenges and develop customized solutions that deliver real results.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={igb}
                alt="Ironglass Byte Logo"
              />
            </div>
          </div>

          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem] ">
              <img
                className="w-full h-auto"
                src={byte}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card  border border-[#34b2fd] rounded-xl p-2 lg:p-4 m-2">
                We provide end-to-end AI services, from initial consultation and strategy development to implementation and ongoing support. Our goal is to help you unlock the potential of AI to improve decision-making, automate processes, and enhance customer experiences. Ironglass Byte is dedicated to delivering AI solutions that are not only cutting-edge but also practical and scalable for your business needs.
              </p>
            </div>
          </div>

          <div className="text-center lg:h-auto text-xl lg:text-center header p-2 lg:p-4 md:p-0 mb-6">
            <h1 className="text-center text-blue2 font-extrabold m-2 p-2 text-4xl lg:text-5xl">
              Our Work Profiles
            </h1>
            
              <Soft_img_slides />
          </div>

          <Testimonals />

          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl">
              Frequently Asked Questions By Our Clients
            </h1>
            <Accordion />
            <br />
            <br />
          </div>

          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl">
          Contact us to harness the power of Artificial Intelligence for your projects!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
