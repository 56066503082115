import React from "react";
import ServiceDocs from "./ServiceDocs";

const Documents = () => {
  return (
    <div className="w-screen h-full p-5  ">
      <div className=" border-[5px] border-blue-900 rounded-xl p-5 m-2">

        <h2 className="text-5xl font-serif text-blue2 flex justify-center  p-3 m-2">
          Explore Our Services Documentation
        </h2>
        <div className="flex">
          <div className="p-2m-2">
            <p className="text-2xl m-1 p2">
              Discover everything you need to know about our services in our
              comprehensive documentation:
            </p>
            <ul>
              <li className="text-lg m-1 p2 text-gray-400">
                <strong>Service Overview:</strong> Learn about our offerings,
                their features, and benefits.
              </li>
              <li className="text-lg m-1 p2 text-gray-400">
                <strong>Service Packages:</strong> Choose from a range of
                packages tailored to your needs.
              </li>
              <li className="text-lg m-1 p2 text-gray-400">
                <strong>Service Procedures:</strong> Follow clear, step-by-step
                instructions for seamless engagement.
              </li>
              <li className="text-lg m-1 p2 text-gray-400">
                <strong>Service Pricing:</strong> Transparent pricing ensures
                fairness and trust.
              </li>
              <li className="text-lg m-1 p2 text-gray-400">
                <strong>Service Support:</strong> Our dedicated team is here to
                assist you at every step.
              </li>
              <li className="text-lg m-1 p2 text-gray-400">
                <strong>Service Contact:</strong> Reach out to our experts for
                further assistance.
              </li>
            </ul>
          </div>

          <div className="  ">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAtkQUKSloIWxaeFakh2KVWfyc6cabJ-1lAw&s"
              className="w-full h-72"
            />
          </div>
        </div>
      <ServiceDocs/>
      </div>
    </div>
  );
};

export default Documents;
