import React, { useState, useEffect } from "react";
import Software from "./Software";
import Hardware from "./Hardware";
import Audio from "./Audio";
import Investment from "./Investment";
import Legal from "./Legal";
import CompanyDetailsForm from "./CompanyDashB";
import Myparticle from "../Particless";
import Buttons from "./Buttons_Dash";

const DashPage = () => {
  const [activeTab, setActiveTab] = useState("overall");
  const [companyData, setCompanyData] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const authStr = localStorage.getItem("auth");
  const authObj = JSON.parse(authStr);
  const userId = authObj?.data?.userData?.id;
  // const userCompnay = authObj?.data?.userData?.id;

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab === "company") {
      fetchCompanyData();
    }
  }, [activeTab]);

  const fetchCompanyData = async () => {
    try {
      const response = await fetch(
        `http://localhost:5000/user/getUser/${userId}`,
        {
          method: "GET", // or POST, PUT, DELETE, etc. depending on your API
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data && data.company) {
        setCompanyData(data);
        setCompanyName(data.company);
      } else {
        console.error("Error fetching company data");
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  return (
    <div>
      {/* <Myparticle /> */}
      {/* <Animi /> */}
      <div className="p-14 m-6  ">
        <div className="w-[100%] p-6 text-white rounded-lg border border-blue-500">
          {/* Tab buttons */}
          <ul className="menu menu-horizontal p-0 flex justify-center mb-4">
            <li
              className={`mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron box__link button-animation rounded-xl ${
                activeTab === "overall" ? "button-animation-always-lit" : ""
              }`}
              onClick={() => handleTabChange("overall")}
            >
              Overall
            </li>
            <li
              className={`mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron box__link button-animation rounded-xl  ${
                activeTab === "company" ? "button-animation-always-lit" : ""
              }`}
              onClick={() => handleTabChange("company")}
            >
              {companyName} Dashboard
            </li>
          </ul>

          {/* Content based on active tab */}
          {activeTab === "overall" && (
            <>
              <div className="flex flex-row min-w-full text-center place-content-center lg:place-content-center md:p-9">
                <p
                  data-aos="fade-center"
                  data-aos-delay="350"
                  data-aos-duration="2500"
                  className="glitch"
                >
                  <span aria-hidden="true"></span>Dashboard
                </p>
              </div>
              <Software />
              <Audio />
              <Investment />
              <Legal />
              <Hardware />
            </>
          )}

          {activeTab === "company" && (
            <CompanyDetailsForm companyNameProp={companyName} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashPage;
