import React from "react";

const Audio = () => {
  return (
    <div>
      <div className="flex p-2 m-2">
        <button className="btn mr-3 bg-black text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-black hover:border-black rounded-lg w-32 h-10 font-orbitron">
          Audio
        </button>
        <div className="w-full border border-blue-500 rounded-lg ">
          <div className="grid grid-cols-6 gap-4 p-2">
            <div className="col-span-1">
              <div
                class="tenor-gif-embed"
                data-postid="22026287"
                data-share-method="host"
                data-aspect-ratio="3.01887"
                data-width="100%"
              >
                <a href="https://tenor.com/view/waves-gif-22026287">
                  {/* Waves Sticker */}
                </a>
                {/* from{" "} */}
                <a href="https://tenor.com/search/waves-stickers">
                  {/* Waves Stickers */}
                </a>
              </div>{" "}
              <script
                type="text/javascript"
                async
                src="https://tenor.com/embed.js"
              ></script>
            </div>
            <div className="col-span-4 flex items-center">
              <p className="font-bold p-3 w-full h-10">
                An Audio file made by us to xyz client
              </p>
            </div>
            <div className="col-span-1 flex items-center justify-end">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5jBwQBOhsgfMMrmBx9ck_-syQinxs2jn_tg&s"
                className=" w-8"
              />
              <a
                href="https://file-examples.com/storage/feed2327706616bd9a07caa/2017/11/file_example_MP3_1MG.mp3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="font-bold p-3">mp3 Audio File</button>
              </a>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 p-2">
            <div className="col-span-1">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMK5McrKZTSs3XApBKLeT7nQzAVSCxDU9-x5G3CvTZiyKBinfncsj43pHuk4YeVGHbw1U&usqp=CAU"
                className="h-12"
              />
            </div>
            <div className="col-span-4 flex items-center">
              <p className="font-bold p-3 w-full h-10">
                An Audio file made by us to xyz client
              </p>
            </div>
            <div className="col-span-1 flex items-center justify-end">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5jBwQBOhsgfMMrmBx9ck_-syQinxs2jn_tg&s"
                className=" w-8"
              />
              <a
                href="https://file-examples.com/storage/feed2327706616bd9a07caa/2017/11/file_example_MP3_1MG.mp3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="font-bold p-3">Wav Audio File</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Audio;
