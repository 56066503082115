import React from "react";
import { Helmet } from "react-helmet";
import growth_icon from "../../../images/igg.png";
import ContactButton from "../../ContactButton";
import Particless from "./Growth_Particles";
import Testimonals from "../Testimonals";
import HR_Payrolls_FAQ from "./HR_Payrolls_FAQ";
import Growth from "../../../videos/hr.gif";

const HrPay = () => {
  return (
    <div className="">

      {/* SEO Metadata */}
      <Helmet>
        <title>HR Payroll Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte provides comprehensive HR Payroll services, including payroll processing, tax filing, and benefits administration, ensuring compliance and accuracy for businesses of all sizes."
        />
        <meta
          name="keywords"
          content="HR Payroll services, payroll processing, tax filing, benefits administration, Ironglass Byte, payroll management"
        />
        <meta property="og:title" content="HR Payroll Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Streamline your payroll operations with Ironglass Byte's HR Payroll services. We offer payroll processing, tax filing, and benefits administration to ensure compliance and accuracy."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/HRPayroll"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Growth_icon_stylized_v003.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/HRPayroll" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl  mb-5 flex text-[#C0C0C0] p-2 lg:p-4 m-2 font-bold ">
              HR Payroll Services
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#3bb24d] rounded-xl bg-black">
                Ironglass Growth provides reliable HR Payroll services to streamline
                your payroll processes and ensure compliance with all regulatory
                requirements. We offer comprehensive payroll solutions, including
                payroll processing, tax filing, benefits administration, and more.
                Our team of HR and payroll experts ensures accurate and timely
                payroll management, reducing errors and enhancing employee
                satisfaction. We handle the complexities of payroll so you can focus
                on your core business activities.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={growth_icon}
                alt="Ironglass Growth Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={Growth} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#3bb24d] rounded-xl p-2 lg:p-4 m-2">
                Our HR Payroll service is designed to be flexible and scalable,
                catering to businesses of all sizes. We stay updated with the latest
                laws and regulations to ensure your payroll operations remain
                compliant and efficient. At Ironglass Growth, we understand the
                importance of accurate payroll management in maintaining employee
                trust and compliance. Trust us to provide dependable payroll
                services that support your business’s financial health and
                operational efficiency.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#C0C0C0]">
              Frequently Asked Questions By Our Clients
            </h1>
            <HR_Payrolls_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#C0C0C0]">
          Contact us to streamline your HR Payroll solutions today!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrPay;
