import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./button.css"; // Import the CSS file
import finance from "../../images/fsub.png";
import Particless from "./finance/Finance_particles";

const Finance_page = () => {
  const [animatedButtons, setAnimatedButtons] = useState({});
  const navigate = useNavigate();

  const handleClick = (buttonId, to) => (evt) => {
    evt.preventDefault();
    setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: true }));
    setTimeout(() => {
      setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: false }));
      navigate(to); // Navigate to the new page after the animation
    }, 1000);
  };

  const renderButton = (to, imgSrc, buttonId, label) => (
    <Link
      to={to}
      className={`btn bubble1 mx-3 p-10 text-xl text-blue2 blue2-border hover-item-sm orbitron ${
        animatedButtons[buttonId] ? "animated" : ""
      }`}
      onClick={handleClick(buttonId, to)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Centers content horizontally
        justifyContent: "center", // Centers content vertically
        textAlign: "center", // Ensures text is centered
        textDecoration: "none",
      }}
    >
      <div style={{ textAlign: "center" }}> {/* Ensures text stays centered */}
        {/* Image rendering */}
        <img
          src={imgSrc}
          className="mt-4 h-44"
          alt={label}
          style={{ display: "block", margin: "0 auto" }} // Ensures image is centered
        />
        {/* Label rendering */}
        {label && (
          <h1 className="text-[#F4C32C] text-xl mt-[-15px]">
            {label}
          </h1>
        )}
      </div>
    </Link>
  );
  

  return (
    <div className="w-screen h-screen mt-[2rem] ">
      <Particless />

      <div className="flex justify-evenly   slide-left">
        {renderButton("/Taxes", finance, "Taxes", "Taxes")}
        {/* {renderButton("",finance_logo)} */}

        {renderButton("/Accounts", finance, "Accounts", "Accounts")}
      </div>
    </div>
  );
};

export default Finance_page;
