import React from "react";
import { Helmet } from "react-helmet";
import ContactButton from "../../ContactButton";
import Testimonals from "../../pages/Testimonals.js";
import "../../pages.css";
import Particless from "./Law_Particles.js";
import Law from "../../../images/igl.png";
import law from "../../../videos/litigation.gif";
import Litigations_FAQ from "./Litigations_FAQ.js";

const Litigations = () => {
  return (
    <div className="">

      {/* SEO Metadata */}
      <Helmet>
        <title>Litigation Services | Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers expert Litigation services for commercial, civil, and criminal disputes. We provide tailored strategies, courtroom representation, and steadfast support."
        />
        <meta
          name="keywords"
          content="Litigation services, legal representation, commercial litigation, civil litigation, criminal defense, dispute resolution, Ironglass Byte, legal strategy"
        />
        <meta property="og:title" content="Litigation Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Resolve your legal disputes with Ironglass Byte's expert Litigation services. Our attorneys provide personalized attention and strategic representation in commercial, civil, and criminal cases."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Litigations"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Law_icon_stylized_v004.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Litigations" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl flex mb-5 text-[#9c682b] p-2 lg:p-4 m-2 font-bold ">
              Litigations
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#9c682b] rounded-xl bg-black">
                When disputes arise, Ironglass Law stands by your side with our
                expert Litigation service. We represent clients in a wide range of
                legal disputes, including commercial, civil, and criminal cases,
                leveraging our extensive courtroom experience to advocate for your
                best interests. Our litigation team is adept at developing strategic
                approaches tailored to each unique case, whether it involves
                negotiation, mediation, or trial. We are committed to achieving
                favorable outcomes, minimizing risks, and providing steadfast
                support throughout the entire legal process.
              </p>
              <img className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10" src={Law} alt="Ironglass Law Logo" />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={law} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#9c682b] rounded-xl bg-black p-2 lg:p-4 m-2">
                At Ironglass Law, our Litigation service is marked by a commitment
                to excellence and client-focused representation. We understand that
                litigation can be a daunting and stressful experience, which is why
                we prioritize clear communication and personalized attention. Our
                attorneys are skilled negotiators and litigators, capable of
                handling even the most complex and high-stakes cases. We keep you
                informed at every stage of the process, ensuring that you understand
                your options and the potential outcomes.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#9c682b]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Litigations_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#9c682b]">
            Contact us to resolve your Litigations swiftly!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Litigations;
