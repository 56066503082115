import React from "react";
import { useParams } from "react-router-dom";
import UploadForm from "./UploadForm";

const AdminUpload = () => {
  // Get user ID from URL
  // const [companyName, setCompanyName] = useState(companyNameProp);

  return (
    <div className="flex justify-center p-14">
      <div className="w-[90%] h-2/3  border-blue-600 border-[5px] rounded-lg">
        <div>
         
          <UploadForm />
        </div>
        {/* <div>
          <h1 className="text-3xl text-blue2  p-10 m-2 font-extrabold flex justify-center">
            Send Email Notification to Client
          </h1>
          <UploadForm />
        </div>
        <div>
          <h1 className="text-3xl text-blue2  p-10 m-2 font-extrabold flex justify-center">
            Send true Notification to Client
          </h1>
          <UploadForm />
        </div> */}
      </div>
    </div>
  );
};

export default AdminUpload;
