import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Accounts_FAQ() {
  return (
    <div className='p-10 text-4xl'>
       <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #D7A233; /* Bronze color */
            border: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #D7A233; /* Bronze color */
            border-bottom: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #D7A233; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What accounting services do you offer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We offer a wide range of accounting services including bookkeeping, financial statement preparation, accounts payable and receivable management, and payroll processing.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do you ensure accuracy in accounting?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We use advanced accounting software and follow rigorous processes to ensure accuracy in all financial records and transactions, providing you with reliable financial data.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Can you help with financial statement preparation?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we prepare comprehensive financial statements that give you a clear view of your financial position, helping you make informed business decisions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What is your approach to managing accounts payable and receivable?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We manage your accounts payable and receivable with precision, ensuring timely payments and collections to maintain healthy cash flow and financial stability.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Do you offer payroll processing services?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we offer comprehensive payroll processing services, ensuring that your employees are paid accurately and on time, and that all payroll taxes are properly handled.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>How can I get started with your accounting services?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To get started, simply contact us to schedule a consultation. We will discuss your accounting needs and develop a customized plan to support your financial goals.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
