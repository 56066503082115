import React from "react";
import { Helmet } from "react-helmet";
import ContactButton from "../../ContactButton";
import Testimonals from "../../pages/Testimonals.js";
import "../../pages.css";
import Particless from "./Law_Particles.js";
import law from "../../../videos/legal-doc.gif";
import Law from "../../../images/igl.png";
import LegalDocuments_FAQ from "./LegalDocuments_FAQ.js";

const Legal_Documents = () => {
  return (
    <div className="">

      {/* SEO Metadata */}
      <Helmet>
        <title>Legal Documents Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers comprehensive Legal Documents services, including drafting, reviewing, and revising contracts, agreements, wills, and more. Ensure your documents are legally sound."
        />
        <meta
          name="keywords"
          content="Legal documents, contract drafting, document review, agreements, wills, trusts, legal contracts, Ironglass Byte, legal services"
        />
        <meta property="og:title" content="Legal Documents Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Secure your legal interests with Ironglass Byte's Legal Documents services. From contracts to agreements, we draft and review documents to ensure legal protection."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/LegalDocuments"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Law_icon_stylized_v004.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/LegalDocuments" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl flex mb-5 text-[#9c682b] p-2 lg:p-4 m-2 font-bold ">
              Legal Documents
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#b48c5e] rounded-xl bg-black">
                Ironglass Law offers a comprehensive Legal Documents service to meet
                all your business and personal needs. We draft, review, and revise
                contracts, agreements, wills, trusts, and more, ensuring that every
                document is legally sound and tailored to your specific
                requirements. Our attorneys provide personalized attention to
                detail, safeguarding your interests and ensuring clarity and
                enforceability. Whether it's a complex commercial contract or a
                simple lease agreement, we deliver precise, reliable legal
                documentation to protect your rights and facilitate smooth
                transactions.
              </p>
              <img className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10" src={Law} alt="Ironglass Law Logo" />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={law} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#b48c5e] rounded-xl bg-black p-2 lg:p-4 m-2">
                Our Legal Documents service at Ironglass Law is designed to provide
                you with peace of mind. We understand that every legal document is a
                critical component of your legal framework, whether in business or
                personal matters. Our team works diligently to ensure that each
                document is meticulously crafted and thoroughly vetted. We
                prioritize clear communication and client collaboration to produce
                documents that reflect your intentions and protect your interests.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#9c682b]">
              Frequently Asked Questions By Our Clients
            </h1>
            <LegalDocuments_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#9c682b]">
            Contact us to manage your Legal Documents securely!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legal_Documents;
