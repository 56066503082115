import React from "react";
import Chart from "../Chart";

const Investment = () => {
  return (
    <div>
      <div className="flex p-2 m-2">
        <button className="btn mr-3 bg-black text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-black hover:border-black rounded-lg w-32 h-10 font-orbitron">
          Investment
        </button>
        <div className="w-full border border-blue-500 rounded-lg flex flex-wrap">
        <div className="grid grid-cols-6 gap-4 p-2">
          <div className="col-span-1">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV-PH5hRzfPOYuQDTBhDrsvKzlIyUjOBvDwg&s"
              className="h-12"
            />
           
          </div>
          <div className="col-span-4 flex items-center">
            <div className="font-bold div-3 w-full h-10 flex justify-evenly">
              <p>Investment  </p>
              <p className="text-orange-200">8000 </p>
              <p >Returns</p>
              <p className="text-green-700 mx-3">  14000+</p>
            </div>
          </div>
          <div className="col-span-1 flex items-center justify-end">
            {/* <img src={gitIcon} className=" w-8" /> */}
            <a
              href="https://github.com/Chandan-Alimilli/webflix_GPT"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="font-bold p-3">Profit/Loss </button>
            </a>
            <p className="text-green-700 mx-3">  + 55%</p>
          </div>
        </div>

        
        </div>
      </div>
    </div>
  );
};

export default Investment;
