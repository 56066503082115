import axios from "axios";
export const newUser = async (user) => {
  console.log(user);
  return await axios.post(`http://localhost:5000/auth/register`, user);
};

export const loginUser = async (user) => {
  // console.log(user)
  return await axios.post(`http://localhost:5000/auth/login`, user);
};

