import React from "react";
import Intro from "./intro2";
import Particless from "./Particless.js";
import Testimonals from "./pages/Testimonals.js";
import Services from "./pages/ServicesHome.js";
import Leadership from "./Leaders.js";
import QuickContact from "./QuickContact.js";

//Home page after loading

function Main() {
  return (
    <div className="section  px-10">
      <Particless />

      <div className="flex flex-row  min-w-full place-content-center ">
        <Intro />
      </div>

      <Services />

      <div className="text  mx-4  justify-center flex-col content-between ml-12 md:block hidden">
        <div className="flex flex-row text-center place-content-center lg:place-content-start min-w-full md:p-20 ">
          <p data-aos="fade-right" data-aos-duration="2200" className="glitch">
            <span aria-hidden="true"></span>BYTE THE GLASS
          </p>
        </div>
        <div className="flex flex-row min-w-full text-center place-content-center lg:place-content-end md:p-20">
          <p
            data-aos="fade-right"
            data-aos-delay="150"
            data-aos-duration="2400"
            className="glitch"
          >
            <span aria-hidden="true"></span>BUILD THE FUTURE
          </p>
        </div>
      </div>

      {/* <div className="mt-[-100px] ml-5">
        <Leadership />
      </div> */}
      <Testimonals />
      <div className="mb-[-40px] px-10">
        
        <QuickContact />
      </div>
      {/* <ByteGpt /> */}
    </div>
  );
}

export default Main;
