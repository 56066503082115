import React from "react";
import { Helmet } from "react-helmet";
import ContactButton from "../../ContactButton.js";
import Testimonals from "../Testimonals.js";
import "../../pages.css";
import Particless from "./Law_Particles.js";
import law from "../../../videos/entity.gif";
import Law from "../../../images/igl.png";
import EntitySetup_FAQ from "./EntitySetup_FAQ.js";

const Entity_Setup = () => {
  return (
    <div className="">

      {/* SEO Metadata */}
      <Helmet>
        <title>Entity Setup Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers comprehensive Entity Setup services to guide you through forming a new business. We help with selecting the appropriate structure, legal compliance, and licensing."
        />
        <meta
          name="keywords"
          content="Entity setup, business formation, LLC formation, corporation setup, legal compliance, operating agreements, Ironglass Byte, business licensing"
        />
        <meta property="og:title" content="Entity Setup Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Set your business up for success with Ironglass Byte's comprehensive Entity Setup services, from legal structure selection to operating agreements and licensing."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/EntitySetup"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Law_icon_stylized_v004.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/EntitySetup" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl flex mb-5 text-[hsl(32,58%,51%)] p-2 lg:p-4 m-2 font-bold ">
              Entity Setup
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#665037] rounded-xl bg-black">
                Starting a new business can be overwhelming, but Ironglass Law
                simplifies the process with our comprehensive Entity Setup service.
                We guide you through selecting the appropriate business structure,
                whether it's an LLC, corporation, partnership, or sole
                proprietorship, ensuring compliance with all legal requirements.
                From drafting operating agreements to obtaining necessary licenses,
                we ensure your business is legally sound from the outset, allowing
                you to focus on growth and success.
              </p>
              <img className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10" src={Law} alt="Ironglass Law Logo" />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={law} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#665037] rounded-xl bg-black p-2 lg:p-4 m-2">
                At Ironglass Law, we believe that a strong legal foundation is the
                key to long-term business success. Our Entity Setup service is
                tailored to your specific needs and business goals. We work closely
                with you to understand your vision and provide customized solutions
                that align with your objectives. Our team stays up-to-date with the
                latest legal developments and regulatory changes to offer informed
                advice. We also provide ongoing support to help your business
                navigate legal challenges as it grows.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#b48c5e]">
              Frequently Asked Questions By Our Clients
            </h1>
            <EntitySetup_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#b48c5e]">
            Contact us to handle your Entity Setup with ease!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entity_Setup;
