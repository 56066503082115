import React from "react";
import { Helmet } from "react-helmet";
import Accordion from "./Accordion2";
import ContactButton from "./ContactButton";
import Testimonals from "./pages/Testimonals.js";
import igb from "../images/igb.png";
import hardware from "../images/Untitled design (3).gif";
import Particless from "./Particless.js";
import byte from "../videos/hp.gif";

const Hardware_Prototype = () => {
  return (
    <div className="">
      <Helmet>
        <title>Hardware Prototype Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers comprehensive hardware prototype services, including design, fabrication, and testing. Turn your ideas into functional prototypes with precision and efficiency."
        />
        <meta
          name="keywords"
          content="Hardware prototype services, prototype design, fabrication, hardware testing, Ironglass Byte"
        />
        <meta property="og:title" content="Hardware Prototype Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Bring your ideas to life with Ironglass Byte's hardware prototype services. From concept to testing, we provide end-to-end prototyping solutions."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Hardware-Prototype"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/IronGlass_Byte-logo.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Hardware-Prototype" />
      </Helmet>

      <Particless />

      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl mb-5 flex text-blue2  p-2 lg:p-4 m-2 font-bold">
              Hardware Prototype
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#34b2fd]">
                At Ironglass Byte, our Hardware Prototype services bring your
                innovative ideas to life with precision and efficiency. We offer
                comprehensive prototyping services, from initial concept and
                design to fabrication and testing. Our team of engineers and
                designers works closely with you to develop functional
                prototypes that meet your specifications and performance
                requirements.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={igb}
                alt="Ironglass Byte Logo"
              />
            </div>
          </div>

          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:mb-0  lg:w-[100rem]">
              <img
                className="w-full h-full lg:h-auto"
                src={byte}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[98%]">
              <p className="text-xl lg:text-2xl card bg-black border border-[#34b2fd] rounded-xl p-2 lg:p-4 m-2">
                We use advanced tools and technologies to create high-quality
                prototypes that help you visualize and refine your product
                before full-scale production. Whether you need a simple
                prototype or a complex, multi-functional model, we have the
                expertise to deliver. Ironglass Byte is dedicated to helping you
                turn your ideas into reality with reliable and efficient
                hardware prototyping solutions.
              </p>
            </div>
          </div>

          <div className="text-center lg:h-auto text-xl lg:text-center header p-2 lg:p-4 md:p-0 mb-6">
            <h1 className="text-center text-blue2 font-extrabold m-2 p-2 text-4xl lg:text-5xl">
              Our Hardware Prototype
            </h1>

            <div className="flex justify-center  ">
              <img src={hardware} className=" card h-[20rem] border border-[#34b2fd] " />
            </div>
          </div>

          <Testimonals />

          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl">
              Frequently Asked Questions By Our Clients
            </h1>
            <Accordion />
            <br />
            <br />
          </div>

          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl">
          Contact us to bring your vision to life with Hardware Prototypes!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hardware_Prototype;
