import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SimpleAccordion() {
  return (
    <div className="text-2xl">
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #34b2fd; /* Blue color */
            border: 1px solid #34b2fd; /* Blue border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #34b2fd; /* Blue color */
            border-bottom: 1px solid #34b2fd; /* Blue border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #9ca3af; /* Blue color */
          }
          .MuiSvgIcon-root {
            color: #34b2fd; /* Blue color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Provide More Value to Your Customers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Mobile apps are one of the major elements of modern tech that are
            consistently changing how consumers shop and satisfy their needs.
            With apps youll make your goods and services more accessable and
            easy to use{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Build a Stronger Brand</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            More than old-school brand marketing options such as TV or outdoor
            advertising, branded apps have proven highly effective in recent
            years
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Increase Loyalty</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            With the addition of personalization, social sharing and Instant
            rewards you will increase loyalty through continual app usage.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Consumer Insight</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            app can serve as a reliable and valuable source of consumer
            insights. By collecting app metrics you will provide a better
            experience for users overall.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Scalability</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Your App will allow you to scale to meet new business requirements.
            You can scale your business app up or down, depending on the needs,
            without delay.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
