import React from "react";

const Legal = () => {
  return (
    <div>
      <div className="flex p-2 m-2">
        <button className="btn mr-3 bg-black text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-black hover:border-black rounded-lg w-32 h-10 font-orbitron">
          Legal
        </button>
        <div className="w-full border border-blue-500 rounded-lg flex flex-wrap">
        <div className="grid grid-cols-6 gap-4 p-2">
            <div className="col-span-1">
              <h1 className="font-bold text-blue2 p-3">6G Data_Cast</h1>
            </div>
            <div className="col-span-4 flex items-center">
              <p className="font-bold p-3 w-full h-10">
                webflix_GPT is a video streaming app made by us, fully
                interactive and dynamic to xyz client
              </p>
            </div>
            <div className="col-span-1 flex items-center justify-end">
              {/* <img src={gitIcon} className=" w-8" /> */}
              <a
                href="https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="font-bold p-3">Documents</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legal;

// import React, { useEffect, useState } from "react";
// import pdfjs from "pdfjs-dist";

// const Legal = () => {
//   const [previewImage, setPreviewImage] = useState(null);

//   useEffect(() => {
//     const fetchAndRenderPDF = async () => {
//       try {
//         // Specify the URL of the PDF document
//         const pdfUrl =
//           "https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf";

//         // Fetch the PDF document
//         const loadingTask = pdfjs.getDocument(pdfUrl);
//         const pdf = await loadingTask.promise;

//         // Load the first page of the PDF
//         const page = await pdf.getPage(1);

//         // Convert the first page to a PNG image
//         const viewport = page.getViewport({ scale: 1.0 });
//         const canvas = document.createElement("canvas");
//         const context = canvas.getContext("2d");
//         canvas.height = viewport.height;
//         canvas.width = viewport.width;
//         await page.render({ canvasContext: context, viewport: viewport })
//           .promise;

//         // Convert canvas to a data URL
//         const imageURL = canvas.toDataURL("image/png");

//         // Set the preview image
//         setPreviewImage(imageURL);
//       } catch (error) {
//         console.error("Error fetching and rendering PDF:", error);
//       }
//     };

//     fetchAndRenderPDF();
//   }, []);

//   const openPDF = () => {
//     // Specify the URL of the PDF document
//     const pdfUrl =
//       "https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf";
//     // Specify the page number you want to navigate to
//     const pageNumber = 1; // Change this to the desired page number

//     // Open the PDF document in a new tab with the specified page
//     window.open(`${pdfUrl}#page=${pageNumber}`, "_blank");
//   };

//   return (
//     <div>
//       <div className="flex p-2 m-2">
//         <button
//           className="btn mr-3 bg-black text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-black hover:border-black rounded-lg w-32 h-10 font-orbitron"
//           onClick={openPDF}
//         >
//           Documents
//         </button>
//         {previewImage && (
//           <img
//             src={previewImage}
//             alt="Document Preview"
//             className="w-32 h-32"
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default Legal;
