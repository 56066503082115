import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import "./QuickContact.css"; // Assuming the CSS file is the same

const AfterPurchaseForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    requirements: "",
  });

  const [submitted, setSubmitted] = useState(false); // State to handle submission

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      customerName: formData.name, // Corrected to use entered name
      name: formData.name, // Corrected to use entered name
      email: formData.email,
      phone: formData.phone,
      service: formData.service,
      requirements: formData.requirements,
      businessName: "N/A", // Default value
      category: "N/A", // Default value
    };

    emailjs
      .send(
        "service_d0ft2t9", // Replace with your EmailJS service ID
        "template_13mfpzr", // Replace with your EmailJS template ID
        templateParams,
        "OEfe_e9ezxj10qqMu" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSubmitted(true); // Set submission state to true
        },
        (err) => {
          console.error("FAILED...", err);
        }
      );
  };

  return (
    <div className="contact-form card mb-[-0.5rem]">
      {submitted ? (
        // Show the confirmation message after submission
        <div className="confirmation-message grid">
          <h2>Hello {formData.name}</h2>
          <p>
            Thank you for purchasing our <strong>{formData.service}</strong>{" "}
            service! We look forward to meeting your requirements.
          </p>
          <p>
            Our team will review your submission and reach out to you soon. In
            the meantime, feel free to contact us if you have any additional
            queries.
          </p>

          <button>
            <Link
              to="/"
              // className="btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron"
            >
              Back To Home
            </Link>
          </button>
        </div>
      ) : (
        // Show the form before submission
        <form onSubmit={handleSubmit}>
          <h2 className="mt-12">Thank You for Your Purchase!</h2>
          <p>We would love to know more about your requirements.</p>
          <div>
            <input
              type="text"
              name="name"
              placeholder="Name *"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email *"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="phone"
              placeholder="Phone *"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="service"
              placeholder="Service Purchased *"
              value={formData.service}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <textarea
              name="requirements"
              placeholder="Your Requirements *"
              value={formData.requirements}
              onChange={handleChange}
              rows="4"
              style={{
                width: "100%",
                margin: "5px",
                padding: "10px",
                backgroundColor: "#111",
                color: "#fff",
                borderRadius: "4px",
              }}
              required
            ></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default AfterPurchaseForm;
