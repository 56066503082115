import React from "react";
import { Helmet } from "react-helmet";
import growth_icon from "../../../images/igg.png";
import AIAccordion from "../../AIAccordion";
import ContactButton from "../../ContactButton";
import Growth from "../../../videos/ad.gif";
import Particless from "./Growth_Particles";
import Testimonals from "../Testimonals";
import Advertisement_Marketing_FAQ from "./Ad_Marketing_FAQ";

const Ad_Market = () => {
  return (
    <div className="">
      {/* SEO Metadata */}
      <Helmet>
        <title>Advertisement Marketing - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte's Advertisement Marketing services are designed to boost your brand's visibility through strategic ad campaigns across digital, social media, and print platforms. Maximize ROI with our data-driven insights."
        />
        <meta
          name="keywords"
          content="Advertisement Marketing, ad campaigns, brand visibility, digital marketing, social media advertising, Ironglass Byte, marketing strategies, advertising ROI"
        />
        <meta property="og:title" content="Advertisement Marketing | Ironglass Byte" />
        <meta
          property="og:description"
          content="Boost your brand's visibility and engagement with Ironglass Byte's Advertisement Marketing services, featuring strategic ad campaigns across multiple platforms."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/AdvertisementMarketing"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Growth_icon_stylized_v003.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/AdvertisementMarketing" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl flex mb-5 text-[#C0C0C0] p-2 lg:p-4 m-2 font-bold ">
              Advertisement Marketing
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#3bb24d] rounded-xl bg-black">
                Ironglass Growth offers comprehensive Advertisement Marketing
                services to boost your brand visibility and reach your target
                audience effectively. Our advertising experts develop and execute
                strategic ad campaigns across various platforms, including digital,
                social media, print, and more. We use data-driven insights to create
                compelling ad content that resonates with your audience and drives
                engagement. Our goal is to maximize your advertising ROI and help
                your brand stand out in a crowded market.
              </p>
              <img
                className=" lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={growth_icon}
                alt="Ironglass Growth Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={Growth} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#3bb24d] rounded-xl p-2 lg:p-4 m-2">
                Our approach to Advertisement Marketing is holistic, encompassing
                market research, creative development, media planning, and campaign
                optimization. We monitor and analyze campaign performance to ensure
                continuous improvement and better results. At Ironglass Growth, we
                are dedicated to helping you achieve your marketing goals through
                innovative and effective advertising strategies. Trust us to elevate
                your brand and drive business growth with our expert advertising
                services.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#C0C0C0]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Advertisement_Marketing_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#C0C0C0]">
            Contact us for expert Ad Marketing services today!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ad_Market;
