import React from "react";
import { Helmet } from "react-helmet";
import Finance from "../../../images/igf.png";
import ContactButton from "../../ContactButton";
import Particless from "./Finance_particles";
import Taxes_FAQ from "./Taxes_FAQ";
import fin_video from "../../../videos/tax.gif";
import fin1 from "../../../images/Untitled design (55).png";
import fin2 from "../../../images/Untitled design (54).png";
import img from "../../../images/fin_pv.mp4";
import FinanceTestimonials from "../Testimonals.js";

const Taxes = () => {
  return (
    <div className="">
      {/* SEO Metadata */}
      <Helmet>
        <title>Taxes Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers comprehensive tax services, including tax preparation, filing, and advisory for businesses and individuals, ensuring compliance and minimizing liabilities."
        />
        <meta
          name="keywords"
          content="Taxes, tax services, tax filing, tax preparation, tax advisory, Ironglass Byte, business tax services, individual tax services"
        />
        <meta property="og:title" content="Taxes Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Expert tax preparation, filing, and advisory services by Ironglass Byte. Simplify your taxes with our professional team."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Taxes"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_finance_icon_stylized_v004.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Taxes" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl flex mb-5 text-[#F4C32C] p-2 lg:p-4 m-2 font-bold ">
              Taxes
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#F8F9FA]">
                At Ironglass Finance, our Taxes service is dedicated to helping
                individuals and businesses navigate the complexities of tax
                compliance and planning. We provide comprehensive tax preparation,
                filing, and advisory services, ensuring you maximize deductions and
                minimize liabilities. Our team of experienced tax professionals
                stays updated with the latest tax laws and regulations to provide
                accurate and effective tax solutions.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={Finance}
                alt="Ironglass Finance Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem] ">
              <img
                className="w-full h-auto"
                src={fin_video}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#F8F9FA] rounded-xl p-2 lg:p-4 m-2">
                We offer personalized tax strategies tailored to your specific
                financial situation and goals. Whether you're a small business
                owner, a corporation, or an individual taxpayer, we help you manage
                your tax obligations efficiently. At Ironglass Finance, we are
                committed to delivering reliable and stress-free tax services that
                allow you to focus on what you do best, knowing your taxes are in
                expert hands.
              </p>
            </div>
          </div>

          {/* Expertise Headline */}
          <div className="text-center lg:h-auto text-xl lg:text-center header p-2 lg:p-4 md:p-0 mb-6">
            <h1 className="text-center text-[#F4C32C] font-extrabold m-2 p-2 text-xl lg:text-4xl">
              Simplify Your Taxes with Trusted Professionals
            </h1>
          </div>

          {/* Images Section */}
          <div className="flex justify-center">
            <div className="p-6">
              <img src={fin1} className="w-[40rem] card m-2" alt="Finance Image 1" />
            </div>

            {/* <div className="card p-6">
              <video
                className="w-[70rem]"
                src={img}
                autoPlay
                muted
                loop
                playsInline
              />
            </div> */}

            <div className="p-6">
              <img src={fin2} className="w-[40rem] m-2 p-6 card" alt="Finance Image 2" />
            </div>
          </div>

          {/* Testimonials */}
          <FinanceTestimonials />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl">
              Frequently Asked Questions By Our Clients
            </h1>
            <Taxes_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl">
            Contact us to simplify your Taxing processes today!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Taxes;
