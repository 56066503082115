import React, { useState } from "react";
import Myparticle from "../Particless";
import { Link } from "react-router-dom";
import { newUser } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./auth.css";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const Navigate = useNavigate();

  const handleSubmit = async (e) => {
    console.log(name, password, email, "here......");
    e.preventDefault();
    try {
      const res = await newUser({ name, email, password, company });
      console.log(res, "response");
      if (res.data) {
        toast.success("You have successfully signed up!");
        Navigate("/DashPage");
        window.localStorage.setItem("auth", JSON.stringify(res.data));
      }
    } catch (err) {
      console.log(
        err,
        "err",
        err.response,
        err.response.data,
        err.response.data.message
      );
      toast.error(err.response.data.message);
      alert(err.response.data.message);
    }
  };

  return (
    <div>
      <div className="bg-black w-screen h-screen mb-20 ">
        <Myparticle />
        <form
          className="w-full md:w-3/12 absolute p-12 bg-black my-36 mx-auto right-0 left-0 text-white rounded-lg bg-opacity-80 z-10 border border-[#0386d6]"
          onSubmit={handleSubmit}
        >
          <h3 className="flex justify-center text-xl orbitron text-blue2">
            Sign Up
          </h3>

          <div className="mb-3">
            <label className="orbitron text-gray-400">Name</label>
            <input
              type="text"
              className="p-2 my-3 rounded-md w-full bg-gray-700 bg-opacity-80"
              placeholder=" Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>

          <div className="mb-3">
            <label className="orbitron text-gray-400">
              Company Name (optional)
            </label>
            <input
              type="text"
              className="p-2 my-3 rounded-md w-full bg-gray-700 bg-opacity-80"
              placeholder="Company Name"
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            />
          </div>

          <div className="mb-3">
            <label className="orbitron text-gray-400">Email address</label>
            <input
              type="email"
              className="p-2 my-3 rounded-md w-full bg-gray-700 bg-opacity-80"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>

          <div className="mb-3">
            <label className="orbitron text-gray-400">Password</label>
            <input
              type="password"
              className="p-2 my-3 rounded-md w-full bg-gray-700 bg-opacity-80"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>

          <div className="flex justify-center">
            <button type="submit" className="box__link button-animation">
              Sign Up
            </button>
          </div>
          <div className="flex justify-center p-3 m-2">
            <h1>
              Already Registered User ?
              <Link to="/Login" className=" text-blue2 m-1 font-bold   ">
                Login
              </Link>
            </h1>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
