import React from "react";
import { Helmet } from "react-helmet";
import "aos/dist/aos.css";
import igb from "../images/igb.png";
import Accordion from "./UIAcordion";
import ContactButton from "./ContactButton";
import Testimonals from "./pages/Testimonals.js";
import Particless from "./Particless.js";
import Soft_img_slides from "./pages/software/Soft_img_slides.js";
import byte from "../videos/web.gif"

function Events() {
  return (
    <div className="">
      <Helmet>
        <title>Website Development Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers expert website development services, delivering visually stunning, responsive, and SEO-optimized websites tailored to your business needs."
        />
        <meta
          name="keywords"
          content="Website development, custom website, responsive design, SEO-friendly, web design, Ironglass Byte"
        />
        <meta property="og:title" content="Website Development Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Ironglass Byte creates responsive, SEO-optimized, and visually appealing websites that enhance your business’s online presence. Contact us today for your custom website."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Websites"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/IronGlass_Byte-logo.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Websites" />
      </Helmet>

      <Particless />

      <div className="flex flex-col  lg:flex-row min-w-full w-screen place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl mb-5 flex lg:text-6xl text-blue2  p-2 lg:p-4 m-2 font-bold">
              Websites
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#34b2fd]">
                At Ironglass Byte, our Website services are designed to create visually stunning and highly functional websites that cater to your business needs. We specialize in custom website development, ensuring that your site is unique, responsive, and optimized for search engines. Our team of experienced developers and designers works collaboratively to bring your vision to life, using the latest technologies and best practices.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={igb}
                alt="Ironglass Byte Logo"
              />
            </div>
          </div>

          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img
                className="w-full h-full lg:h-auto"
                src={byte}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[94%]">
              <p className="text-xl lg:text-2xl card bg-black border border-[#34b2fd] rounded-xl p-2 lg:p-4 m-2">
                We use the latest technologies and best practices to build responsive, fast-loading, and SEO-friendly websites. Whether you need a simple brochure site or a complex e-commerce platform, Ironglass Byte delivers solutions that drive traffic and engagement. Our commitment to quality and customer satisfaction ensures that your website will be a powerful tool for your business success.
              </p>
            </div>
          </div>

          <div className="text-center lg:h-auto text-xl lg:text-center header p-2 lg:p-4 md:p-0 mb-6">
            <h1 className="text-center text-blue2 font-extrabold m-2 p-2 text-4xl lg:text-5xl">
              Our Work Profiles
            </h1>
            
              <Soft_img_slides/>
          </div>

          <Testimonals />

          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl">
              Frequently Asked Questions By Our Clients
            </h1>
            <Accordion />
            <br />
            <br />
          </div>

          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl">
          Contact us to design your Website with cutting-edge Web Design!
          </p>
          <br />
          <div className="mb-[-3rem] ">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
