import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function EntitySetup_FAQ() {
  return (
    <div className='p-10'>
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border-bottom: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #9ca3af; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #b48c5e; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is Entity Setup?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Entity Setup involves selecting the appropriate business structure and ensuring compliance with legal requirements for starting a new business.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>What types of business structures can be chosen?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Business structures include LLC, corporation, partnership, and sole proprietorship, each with its own legal and tax implications.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>How does Ironglass Law assist with Entity Setup?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Ironglass Law guides you through the selection process, handles all paperwork, filings, and registrations to ensure your business is legally sound.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What documents are necessary for Entity Setup?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Necessary documents include operating agreements, articles of incorporation, partnership agreements, and any required licenses or permits.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How long does it take to complete the Entity Setup process?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The time frame for Entity Setup varies depending on the complexity of the business structure and the jurisdictional requirements.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
