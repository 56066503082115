import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Advertisement_Marketing_FAQ() {
  return (
    <div className='p-10 text-4xl'>
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #3bb24d; /* Bronze color */
            border: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #C0C0C0; /* Bronze color */
            border-bottom: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #3bb24d; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is included in your Advertisement Marketing service?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our Advertisement Marketing service includes strategic ad campaigns across digital, social media, print, and other platforms to enhance your brand visibility.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do you create effective ad campaigns?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We use data-driven insights to create compelling ad content that resonates with your audience and drives engagement.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>What platforms do you use for advertising?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We utilize various platforms, including digital, social media, print, and more, to reach your target audience effectively.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>How do you measure the success of an ad campaign?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We monitor and analyze campaign performance using key metrics such as engagement rates, conversions, and ROI to ensure continuous improvement.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Can you help with social media advertising?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we specialize in social media advertising, creating targeted ad campaigns that increase your brand’s visibility and engagement on social platforms.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Do you provide customized ad strategies?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we develop customized ad strategies tailored to your business goals and target audience to ensure maximum effectiveness.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
