import React from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import igb from "../images/igb.png";
import Accordion from "./UIAcordion";
import ContactButton from "./ContactButton";
import Testimonals from "./pages/Testimonals.js";
import Soft_img_slides from "./pages/software/Soft_img_slides.js";
import byte from "../videos/ui-ux.gif";
import Particless from "./Particless.js";

function Events() {
  return (
    <div className="">
      <Helmet>
        <title>UI/UX Design Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers exceptional UI/UX design services that focus on creating intuitive and visually appealing interfaces. Our user-centered design approach enhances both functionality and usability, driving user engagement and satisfaction."
        />
        <meta
          name="keywords"
          content="UI/UX design, user experience, user interface, wireframing, prototyping, visual design, Ironglass Byte"
        />
        <meta property="og:title" content="UI/UX Design Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Ironglass Byte provides cutting-edge UI/UX design services that focus on creating user-friendly, visually stunning interfaces to enhance user experience and satisfaction."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/UI-UX"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/IronGlass_Byte-logo.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/UI-UX" />
      </Helmet>

      <Particless />

      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl mb-5 flex lg:text-6xl text-blue2  p-2 lg:p-4 m-2 font-bold">
              UI/UX
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#34b2fd]">
                At Ironglass Byte, our UI/UX services are focused on creating
                intuitive and aesthetically pleasing interfaces that enhance
                user satisfaction and engagement. We employ a user-centered
                design approach, ensuring that every aspect of the interface is
                optimized for usability and functionality. Our designers conduct
                thorough research and usability testing to understand user needs
                and behaviors, crafting experiences that are both beautiful and
                effective.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={igb}
                alt="Ironglass Byte Logo"
              />
            </div>
          </div>

          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img
                className="w-full h-full lg:h-auto"
                src={byte}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[98%]">
              <p className="text-xl lg:text-2xl card bg-black border border-[#34b2fd] rounded-xl p-2 lg:p-4 m-2">
                Our UI/UX services include wireframing, prototyping, user
                testing, and visual design, all aimed at delivering a seamless
                user experience. We believe that great design is not just about
                looks but also about how it works. By prioritizing user
                experience, we help businesses create products that are easy to
                navigate and delightful to use, ultimately driving user
                retention and satisfaction.
              </p>
            </div>
          </div>

          <div className="text-center lg:h-auto text-xl lg:text-center header p-2 lg:p-4 md:p-0 mb-6">
            <h1 className="text-center text-blue2 font-extrabold m-2 p-2 text-4xl lg:text-5xl">
              Our Work Profiles
            </h1>
            
              <Soft_img_slides />
          </div>

          <Testimonals />

          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl">
              Frequently Asked Questions By Our Clients
            </h1>
            <Accordion />
            <br />
            <br />
          </div>

          <br />
          <div className="mb-[-3rem]">
            <h1 className="text-2xl lg:text-4xl font-bold flex justify-center p-4">
              Contact us to build intuitive user experiences with our UI/UX designs!
            </h1>
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
