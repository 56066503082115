import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function LegalDocuments_FAQ() {
  return (
    <div>
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border-bottom: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #9ca3af; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #b48c5e; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What are Legal Documents?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Legal Documents encompass contracts, agreements, wills, trusts, and other written instruments that create, modify, or terminate legal rights.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How can Ironglass Law assist with Legal Documents?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Ironglass Law drafts, reviews, and revises legal documents to ensure they are legally sound and tailored to your specific needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Why is it important to have legally sound documents?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Legally sound documents protect your interests, ensure clarity and enforceability, and prevent future disputes or legal issues.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What types of Legal Documents does Ironglass Law handle?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We handle a wide range of documents, including commercial contracts, employment agreements, lease agreements, wills, and trusts.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Can Ironglass Law help with the revision of existing documents?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we can review and revise your existing legal documents to ensure they meet current legal standards and adequately protect your interests.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
