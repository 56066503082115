import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import production from "../../images/asub.png";
import "./button.css"; // Import the CSS file
import Particless from "./production/Prodution_Particless";

const AudioPage = () => {
  const [animatedButtons, setAnimatedButtons] = useState({});
  const navigate = useNavigate();

  const handleClick = (buttonId, to) => (evt) => {
    evt.preventDefault();
    setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: true }));
    setTimeout(() => {
      setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: false }));
      navigate(to); // Navigate to the new page after the animation
    }, 1000);
  };

  const renderButton = (to, imgSrc, buttonId, label) => (
    <Link
      to={to}
      className={`btn bubble1 mx-3 p-10 text-xl text-blue2 blue2-border hover-item-sm orbitron ${
        animatedButtons[buttonId] ? "animated" : ""
      }`}
      onClick={handleClick(buttonId, to)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Centers content horizontally
        justifyContent: "center", // Centers content vertically
        textAlign: "center", // Ensures text is centered
        textDecoration: "none",
      }}
    >
      <div style={{ textAlign: "center" }}>
        {" "}
        <img
          src={imgSrc}
          className=" "
          alt={label}
          style={{ display: "block", margin: "0 auto" }} // Ensures image is centered
        />
        {/* Label rendering */}
        {label && (
          <h1 className="text-[#ec0625] text-xl mt-[-65px]">{label}</h1>
        )}
      </div>
    </Link>
  );

  return (
    <div className="mt-[2rem] w-screen">
      <Particless />

      <div className="flex justify-evenly ">
        {renderButton("/Mixing", production, "mixing2", "Mixing", "red-700")}
        {renderButton(
          "/Mastering",
          production,
          "mastering1",
          "Mastering",
          "red-700"
        )}

        {renderButton(
          "/Production",
          production,
          "production",
          "Production",
          "red-700"
        )}
      </div>
      <div className="h-36"></div>
    </div>
  );
};

export default AudioPage;
