import axios from "axios";

export const getAllUser = async () => {
  // console.log(user)
  return await axios.get(`http://localhost:5000/user/getAllUsers`);
};

export const updateUser = async (data) => {
  return await axios.post(`http://localhost:5000/user/updateUser`, data);
};

export const getUserById = async (id) => {
  return await axios.get(`http://localhost:5000/user/getUser/${id}`);
};