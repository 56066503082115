import React from "react";
// import { Document, Page } from "react-pdf";

const ServiceDocs = () => {
  // const pdfUrl =
  //   "https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf";

  return (
    <div className="p-5 m-3">
      <h1 className="text-3xl text-blue2 font-serif flex justify-center">
        ServiceDocs
      </h1>

      <div className="flex  flex-wrap p-4 justify-center">
        {/* <Document file={pdfUrl} className="border border-blue2">
          <Page pageNumber={1} />
        </Document> */}


        <div className=" p-5 m-2">
          <img src="https://meetingtomorrow.com/wp-content/uploads/2019/08/TextDocument.png"
          className=" h-96"/>
          <h1 className="text-xl text-blue2 font-bold  py-2">Software service documentation</h1>
        </div>
        <div className=" p-5 m-2">
          <img src="https://meetingtomorrow.com/wp-content/uploads/2019/08/TextDocument.png"
          className=" h-96"/>
          <h1 className="text-xl text-blue2 font-bold  py-2">Software service documentation</h1>
        </div>
        <div className=" p-5 m-2">
          <img src="https://meetingtomorrow.com/wp-content/uploads/2019/08/TextDocument.png"
          className=" h-96"/>
          <h1 className="text-xl text-blue2 font-bold  py-2">Software service documentation</h1>
        </div>
        <div className=" p-5 m-2">
          <img src="https://meetingtomorrow.com/wp-content/uploads/2019/08/TextDocument.png"
          className=" h-96"/>
          <h1 className="text-xl text-blue2 font-bold  py-2">Software service documentation</h1>
        </div>
        <div className=" p-5 m-2">
          <img src="https://meetingtomorrow.com/wp-content/uploads/2019/08/TextDocument.png"
          className=" h-96"/>
          <h1 className="text-xl text-blue2 font-bold  py-2">Software service documentation</h1>
        </div>

      </div>
    </div>
  );
};

export default ServiceDocs;
