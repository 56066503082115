import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Discovery_FAQ() {
  return (
    <div className='p-10 text-4xl'>
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border-bottom: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #b48c5e; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is Discovery in legal services?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Discovery is the process of gathering, analyzing, and presenting all relevant information crucial to a case. This includes collecting evidence, depositions, and documents.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How does Ironglass Law handle Discovery?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            At Ironglass Law, we use advanced legal technologies and thorough investigative techniques to ensure comprehensive information gathering.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Why is Discovery important in litigation?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Discovery is crucial as it provides a comprehensive understanding of the facts, helping to build a strong legal strategy and making informed decisions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What types of evidence are collected during Discovery?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Evidence collected during Discovery can include documents, electronic records, depositions, interrogatories, and admissions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How long does the Discovery process take?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The duration of the Discovery process varies depending on the complexity of the case and the amount of information to be gathered and analyzed.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
