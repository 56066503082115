// import React, { useState } from "react";
// import ContactButton from "../ContactButton";
// import { Link } from "react-router-dom";
// import Buttons from "./Buttons_Dash";
// // import AdminOverview from "./AdminOverviwe";

// const CompanyDetailsForm = ({ companyNameProp }) => {
//   // console.log(companyNameProp, "name");
//   const [companyName, setCompanyName] = useState(companyNameProp);
//   const [logo, setLogo] = useState(null);
//   const [details, setDetails] = useState("");

//   const authStr = localStorage.getItem("auth");
//   const authObj = JSON.parse(authStr);
//   const Admin = authObj?.data?.userData?.isAdmin;

//   const handleLogoChange = (e) => {
//     const file = e.target.files[0];
//     setLogo(file);
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     // Here you can handle form submission, like sending data to a server or storing it locally
//     console.log("Company Name:", companyName);
//     console.log("Logo:", logo);
//     console.log("Details:", details);
//   };

//   return (
//     // <div>
//     //   <AdminOverview/>
//     // </div>
//     <div>
//       {/* <div className=" min-h-screen z-10 flex flex-col justify-center items-center bg-black">
//       <h1 className="text-4xl font-bold  text-blue2">{companyNameProp}</h1>
//       <div className="flex flex-row min-w-full text-center place-content-center lg:place-content-center md:p-9">
//         <p
//           data-aos="fade-center"
//           data-aos-delay="350"
//           data-aos-duration="2500"
//           className="glitch"
//         >
//           <span aria-hidden="true"></span> Services Dashboard
//         </p>
//       </div>

//       {/* <Documents /> */}
//       <Buttons />

//       <form
//         onSubmit={handleFormSubmit}
//         className="bg-black shadow-md rounded px-8 pt-6 pb-8 mb-4"
//       >
//         <div className="mb-4">
//           <label
//             htmlFor="companyName"
//             className="block text-gray-700 text-sm font-bold mb-2"
//           >
//             Company Name:
//           </label>
//           <input
//             type="text"
//             id="companyName"
//             value={companyName}
//             onChange={(e) => setCompanyName(e.target.value)}
//             required
//             className="shadow appearance-none border bg-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-4">
//           <label
//             htmlFor="logo"
//             className="block text-gray-400 text-md font-bold mb-2"
//           >
//             Upload Documents:
//           </label>
//           <input
//             type="file"
//             id="logo"
//             accept="image/*"
//             onChange={handleLogoChange}
//             required
//             className="shadow appearance-none border rounded w-full bg-gray-500  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-4">
//           <label
//             htmlFor="logo"
//             className="block text-gray-400 text-md font-bold mb-2"
//           >
//             Upload Logo:
//           </label>
//           <input
//             type="file"
//             id="logo"
//             accept="image/*"
//             onChange={handleLogoChange}
//             required
//             className="shadow appearance-none border rounded w-full bg-gray-500  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-6">
//           <label
//             htmlFor="details"
//             className="block text-gray-400 text-md font-bold mb-2"
//           >
//             service Details:
//           </label>
//           <textarea
//             id="details"
//             value={details}
//             onChange={(e) => setDetails(e.target.value)}
//             required
//             className="shadow appearance-none border rounded bg-gray-500  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <button
//           type="submit"
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//         >
//           Submit
//         </button>
//       </form>

//       <div>
//         <h1 className="text-blue2 text-2xl p-2 m-2 orbitron">
//           Bulid your own Dasboard with Ironglass Byte
//         </h1>
//         <ContactButton />
//       </div>
//     </div>
//   );
// };

// export default CompanyDetailsForm;

import React, { useState, useEffect } from "react";
import { getAllUser } from "../actions/users";
import AdminOverview from "../admin/AdminOverviwe"; // Import AdminOverview component
// import CompanyDetailsForm from "./CompanyDetailsForm"; // Import CompanyDetailsForm component
import ContactButton from "../ContactButton";
import { Link } from "react-router-dom";
import Buttons from "./Buttons_Dash";

const Dashboard = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const res = await getAllUser();
        if (res.success) {
          const authStr = localStorage.getItem("auth");
          const authObj = JSON.parse(authStr);
          const isAdmin = authObj?.data?.userData?.isAdmin;
          setIsAdmin(isAdmin);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    }
    fetchUserData();
  }, []);

  return (
    <div>
      {isAdmin ? (
        <AdminOverview />
      ) : (
        // <CompanyDetailsForm companyNameProp="Your Company Name" />
        <div>
          <h1>company Dashboard</h1>
        </div>
      )}

      <div>
        <h1 className="text-blue2 text-2xl p-2 m-2 orbitron">
          Bulid your own Dasboard with Ironglass Byte
        </h1>
        <ContactButton />
      </div>
    </div>
  );
};

export default Dashboard;
