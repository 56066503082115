import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Staffing_FAQ() {
  return (
    <div className='p-10 text-4xl'>
    <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #3bb24d; /* Bronze color */
            border: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #C0C0C0; /* Bronze color */
            border-bottom: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #3bb24d; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What staffing services do you offer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We offer comprehensive staffing solutions, including temporary, permanent, and contract placements tailored to your business needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do you find the right candidates?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We use advanced recruitment technologies and a vast network of professionals to source and screen qualified candidates who fit your company culture and objectives.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>What industries do you serve?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We serve a wide range of industries, ensuring that we can meet the specific staffing needs of businesses in various sectors.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>Do you offer temporary staffing solutions?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we provide temporary staffing solutions to help businesses manage short-term projects, seasonal workloads, and other temporary needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How do you ensure a good fit between the candidate and the company?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We thoroughly assess candidates' skills, experience, and cultural fit through detailed interviews and background checks to ensure they meet your business's specific requirements.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>What is your process for onboarding new hires?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We handle the entire onboarding process, including paperwork, training, and integration into your team, ensuring a smooth transition for new hires.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
