import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import "./QuickContact.css"; // Assuming your styles are in this file

const QuickContact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    businessName: "",
    phone: "",
    category: "",
    service: "",
  });

  const [servicesList, setServicesList] = useState([]);
  const [submitted, setSubmitted] = useState(false); // State to handle submission

  const categories = {
    Growth: [
      "Ad Marketing",
      "Staffing",
      "Content Marketing",
      "Sales",
      "HR Payrolls",
      "SEO",
    ],
    Production: ["Mixing", "Mastering", "Music Production"],
    Software: [
      "Blockchain",
      "Cyber Security",
      "Web Design",
      "Artificial Intelligence",
      "Apps",
      "Hardware Prototype",
      "UI/UX",
    ],
    Finance: ["Accounts", "Taxing"],
    Legal: [
      "Patents",
      "Discovery",
      "Entity Setup",
      "Legal Documents",
      "Litigations",
    ],
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setFormData({ ...formData, category: selectedCategory, service: "" });
    setServicesList(categories[selectedCategory] || []);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      email: formData.email,
      businessName: formData.businessName,
      phone: formData.phone,
      category: formData.category,
      service: formData.service,
    };

    emailjs
      .send(
        "service_31f0lbd",
        "template_l5v3teq",
        templateParams,
        "fbekasB_V7DVnEMKJ"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSubmitted(true); // Set submission state to true
        },
        (err) => {
          console.error("FAILED...", err);
        }
      );
  };

  return (
    <div className="contact-form card mb-[-0.5rem]">
      {submitted ? (
        // Show the confirmation message after submission
        <div className="confirmation-message grid ">
          <h2>Hello {formData.name}</h2>
          <p>
            We truly appreciate your interest in our{" "}
            <strong>{formData.category}</strong> and{" "}
            <strong>{formData.service}</strong> services to help grow your
            business, <strong>{formData.businessName}</strong>.
          </p>
          <p>
            We believe our solutions are designed to deliver exceptional value
            and align perfectly with your business needs. Whether you're looking
            to enhance visibility, streamline operations, or drive growth, we're
            here to support you every step of the way.
          </p>
          <p>
            Our team of experts has extensive experience in{" "}
            <strong>{formData.category}</strong> and{" "}
            <strong>{formData.service}</strong>, and we are confident that we
            can provide you with the insights, strategies, and tools you need to
            take your business to the next level.
          </p>

          <button>
            <Link
              to="/"
              // className="btn mr-3 bg-black text-blue2 blue2-border hover-item-sm orbitron"
            >
              Back To Home
            </Link>
          </button>
        </div>
      ) : (
        // Show the form before submission
        <form onSubmit={handleSubmit}>
          <h2 className="mt-12">Let's <span className="text-blue2">BUILD THE FUTURE </span>!</h2>
          <p >Request a FREE Service Consultation with our Experts</p>
          <div>
            <input
              type="text"
              name="name"
              placeholder="Name *"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email *"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <input
              type="text"
              name="businessName"
              placeholder="Business Name *"
              value={formData.businessName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone *"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <select
              name="category"
              value={formData.category}
              onChange={handleCategoryChange}
              required
            >
              <option value="">Select Category *</option>
              {Object.keys(categories).map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
            <select
              name="service"
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="">Select Service *</option>
              {servicesList.map((service, index) => (
                <option key={index} value={service}>
                  {service}
                </option>
              ))}
            </select>
          </div>
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default QuickContact;
