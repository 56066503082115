import React, { useState, useEffect } from "react";
import { getAllUser } from "../actions/users";
import { Link } from "react-router-dom";

const AdminOverview = () => {
  const [users, setUsers] = useState([]);

  async function fetchData() {
    try {
      const res = await getAllUser();
      console.log(res, "response");
      console.log(res.data, "response data");

      if (res.data.success) {
        setUsers(res.data.data);
      } else {
        console.error("Failed to fetch users");
      }
    } catch (error) {
      console.error("Error fetching users", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className=" p-16  rounded-xl border-2 border-blue-500">
      <h2 className="text-2xl font-bold p-4 text-blue2">User Overview</h2>
      <table>
        <thead>
          <tr>
            <th className="p-2 m-1">Company</th>
            <th className="p-2 m-1">Name</th>
            <th className="p-2 m-1">Email</th>
            <th className="p-2 m-1">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td className="p-5 m-2">{user.company || "-"}</td>
              <td className="p-5 m-2">{user.name}</td>
              <td className="p-5 m-2">{user.email}</td>
              <td>
                <Link
                  to={`/AdminUpload/${user._id}`}
                  className=" text-black m-2 font-bold  border-[4px] border-sky-500 bg-sky-500 rounded-lg py-1 px-4"
                >
                  <button className="p-5 m-2 " value={user._id}>Upload</button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminOverview;
