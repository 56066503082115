import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Content_Marketing_FAQ() {
  return (
    <div className='p-10 text-4xl'>
     <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #3bb24d; /* Bronze color */
            border: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #C0C0C0; /* Bronze color */
            border-bottom: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #3bb24d; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is Content Marketing?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Content Marketing involves creating and distributing valuable, relevant content to attract and engage your target audience.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>What types of content do you create?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We create a variety of content, including blog posts, articles, videos, infographics, and more, tailored to your audience's needs and preferences.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>How does Content Marketing benefit my business?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Content Marketing helps build your brand’s authority, attract and retain a loyal customer base, and drive organic traffic to your website.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>How do you develop a content strategy?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We develop a content strategy by understanding your business goals, target audience, and creating content that addresses their pain points and interests.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How do you measure the effectiveness of content marketing?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We use analytics and insights to measure the effectiveness of content marketing efforts, focusing on metrics like engagement, traffic, and conversions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Do you provide ongoing content creation and management?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we provide ongoing content creation and management services to ensure a consistent and effective content marketing strategy for your business.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
