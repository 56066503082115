import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Sales_FAQ() {
  return (
    <div className='p-10 text-4xl'>
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #3bb24d; /* Bronze color */
            border: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #C0C0C0; /* Bronze color */
            border-bottom: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #3bb24d; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What does the Sales service include?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our Sales service includes customized sales strategies, lead generation, sales training, and optimization of sales processes to improve conversion rates.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How does Ironglass Growth improve sales productivity?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We use data-driven techniques and industry insights to optimize sales processes and provide effective training for your sales team.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Can you help with lead generation?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we assist with lead generation by identifying and targeting your ideal customers to increase your sales opportunities.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What industries do you serve?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We serve a wide range of industries, tailoring our sales strategies to fit the specific needs and goals of each business.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How do you measure the success of sales strategies?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We measure success through key performance indicators (KPIs) such as conversion rates, sales growth, and customer retention.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Do you offer sales training?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we offer comprehensive sales training to equip your team with the skills and knowledge needed to excel in today’s competitive market.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
