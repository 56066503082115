import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SimpleAccordion() {
  return (
    <div className="border  border-blue-400 rounded-xl">
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #34b2fd; /* Blue color */
            border: 1px solid #34b2fd; /* Blue border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #34b2fd; /* Blue color */
            border-bottom: 1px solid #34b2fd; /* Blue border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #9ca3af; /* Blue color */
          }
          .MuiSvgIcon-root {
            color: #34b2fd; /* Blue color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Increase user retention</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            When effectively curated, your user design increases repeat sales.
            An interface with a great layout and easy to navigate will attract
            loyal customers who want to go with you to the digital experience.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Boost branding</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            UI/UX design boosts brand identity and credibility. It helps to
            establish positive customer relationships and inspire loyalty.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Attract New Customer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            With outstanding UI/UX, the right approach will pique customer
            interest and eventually lead to fruitful conversions{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Competition</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            UX is a competitive differentiator. Most customers expect a
            fantastic user experience. With a fantanstic UI/UX your buisness
            will dominate the competition
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Data Integrity</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Using cryptographic methods data stored on the blockchain is
            immutable and indisputable. Malicious actors cannot forge
            transactions or other data that has already been made public and
            stored on chain.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
