import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import law from "../../images/lsub.png";
import "./button.css"; // Import the CSS file
import Particless from "./Law/Law_Particles";

const LegalPage = () => {
  const [animatedButtons, setAnimatedButtons] = useState({});
  const navigate = useNavigate();

  const handleClick = (buttonId, to) => (evt) => {
    evt.preventDefault();
    setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: true }));
    setTimeout(() => {
      setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: false }));
      navigate(to); // Navigate to the new page after the animation
    }, 1000);
  };

  const renderButton = (to, imgSrc, buttonId, label) => (
    <Link
      to={to}
      className={`btn bubble1 mx-3 p-10 text-xl text-blue2 blue2-border hover-item-sm orbitron ${
        animatedButtons[buttonId] ? "animated" : ""
      }`}
      onClick={handleClick(buttonId, to)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textDecoration: "none",
      }}
    >
      <div>
      <img src={imgSrc} className=" mt-[-30px] " /> { " "}
      {label && <h1 className="text-[#c8944f] text-xl mt-[-60px]">{label}</h1>}
      </div>
      
    </Link>
  );

  return (
    <div className=" mt-[2rem]  w-screen">
      <Particless />
      <div className=" ">
        <div className="flex justify-center slide-right" style={{ gap: '2rem' }}>
          {renderButton("/Patents", law, "patents", "Patents")}
          {renderButton("/Entity_Setup", law, "entity_setup", "Entity Setup")}
        </div>

        <div className="flex justify-center slide-left" style={{ gap: '2rem', marginTop: '1rem' }}>
          {renderButton("/Discovery", law, "discovery", "Discovery")}
          {renderButton("/Legal_Documents", law, "legal_documents", "Legal Documents")}
          {renderButton("/Litigations", law, "litigations", "Litigations")}
        </div>

        <div className="h-36"></div>
      </div>
    </div>
  );
};

export default LegalPage;
