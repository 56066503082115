import React, { useState } from "react";
import Myparticle from "../Particless";
import { Link } from "react-router-dom";
import { loginUser } from "../actions/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "./auth.css";
// import DashHome from "../Dasboard/DashHome";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const Navigate = useNavigate();
  const [token, setToken] = useState("");

  const handleSubmit = async (e) => {
    console.log(password, email, "login here......");
    e.preventDefault();
    try {
      const res = await loginUser({ email, password });
      console.log(res, "response");
      if (res.data) {
        toast.success("You have succesfully login up!");
        Navigate("/DashPage");
        window.localStorage.setItem("auth", JSON.stringify(res.data));
        setToken(localStorage.getItem("auth"));
        window.location.reload();
      }
    } catch (err) {
      console.log(
        err,
        "err",
        err.response,
        err.response.data,
        err.response.data.message
      );
      toast.error(err.response.data.message);
      alert(err.response.data.message);
    }
  };
  return (
    <div>
      <Myparticle />

      <div className="bg-black  w-screen h-screen">
        <form
          className="w-full md:w-3/12 absolute p-12 bg-black my-36 mx-auto right-0 left-0 text-white rounded-lg bg-opacity-80 border border-[#0386d6]"
          onSubmit={handleSubmit}
        >
          <h3 className="flex justify-center text-xl orbitron text-blue2">
            Sign In
          </h3>

          <div className="p-2 my-2 ">
            <label className="orbitron text-gray-400">Email address</label>
            <input
              type="email"
              className="p-2 my-3 rounded-md w-full bg-gray-700 bg-opacity-80"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>

          <div className="p-2 ">
            <label className="orbitron text-gray-400">Password</label>
            <input
              type="password"
              className="p-2 rounded-md my-3  w-full bg-gray-700 bg-opacity-80"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>

          <div className="flex justify-center ">
            <button type="submit" className="box__link button-animation">
              Submit
            </button>
          </div>
          <div className="flex justify-center p-3 m-2">
            <h1>
              New User ?
              <Link to="/Singup" className=" text-blue2 m-1 font-bold   ">
                Singup
              </Link>
            </h1>
          </div>
        </form>
        {/* <DashHome/> */}
      </div>
    </div>
  );
};

export default Login;
