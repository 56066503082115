import React from "react";
import { Helmet } from "react-helmet";
import Accordion from "./Accordion2";
import ContactButton from "./ContactButton";
import Testimonals from "./pages/Testimonals.js";
import "./pages.css";
import igb from "../images/igb.png";
import Particless from "./Particless.js";
import byte from "../videos/cyber.gif";


const Cyber_Security = () => {
  return (
    <div className="">
      <Helmet>
        <title>Cyber Security Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte provides comprehensive Cyber Security services, including risk assessment, vulnerability management, and incident response. Protect your business with our proactive security solutions."
        />
        <meta
          name="keywords"
          content="Cyber Security services, risk assessment, vulnerability management, incident response, security monitoring, cyber threats, Ironglass Byte"
        />
        <meta property="og:title" content="Cyber Security Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Protect your business from cyber threats with Ironglass Byte's comprehensive Cyber Security services, including risk assessment, vulnerability management, and more."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Cyber-Security"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/IronGlass_Byte-logo.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Cyber-Security" />
      </Helmet>

      <Particless />

      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl mb-5 flex lg:text-6xl text-blue2  p-2 lg:p-4 m-2 font-bold">
              Cyber Security
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#34b2fd]">
                Ironglass Byte offers comprehensive Cyber Security services to
                protect your business from digital threats and ensure the
                integrity of your data. Our services include risk assessment,
                vulnerability management, incident response, and security
                monitoring. We employ a proactive approach to cyber security,
                identifying potential threats and implementing robust defenses
                to mitigate risks.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={igb}
                alt="Ironglass Byte Logo"
              />
            </div>
          </div>

          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:mb-0 lg:w-[100rem] ">
              <img
                className="w-full h-auto"
                src={byte}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[98%]">
              <p className="text-xl lg:text-2xl card bg-black border border-[#34b2fd] rounded-xl p-2 lg:p-4 m-2">
                Our team of cyber security experts is skilled in the latest
                technologies and best practices, ensuring your systems are
                secure against evolving threats. We provide tailored security
                solutions that address your specific vulnerabilities and
                compliance requirements. At Ironglass Byte, we are committed to
                safeguarding your business from cyber attacks and ensuring the
                continuity of your operations.
              </p>
            </div>
          </div>

          {/* <div className="text-center    lg:h-auto text-xl lg:text-center header p-2 lg:p-4 md:p-0 mb-6">
            <h1 className="text-center text-blue2 font-extrabold m-2 p-2 text-4xl lg:text-5xl">
              Our Expertise In Cyber Security
            </h1>
            <div className="flex justify-center">
              <video
                className=" h-[32rem] card flex justify-center"
                src={cyber}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>
          </div> */}

          <Testimonals />

          <div className="mb-[-2rem]">
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl">
              Frequently Asked Questions By Our Clients
            </h1>
            <Accordion />
            <br />
            <br />
          </div>

          <br />
          <p className="text-center font-extrabold text-2xl  lg:text-3xl">
            Contact us to safeguard your business with Cyber Security services!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cyber_Security;
