import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Mixing_FAQ() {
  return (
    <div className='p-10 text-4xl'>
       <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #9c2525; /* Bronze color */
            border: 1px solid #9c2525; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #C0C0C0; /* Bronze color */
            border-bottom: 1px solid #9c2525; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #9c2525; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is audio mixing?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Audio mixing involves combining individual audio tracks into a single cohesive piece, balancing levels, EQ, and adding effects to enhance the overall sound.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How does Ironglass Production approach mixing?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We use state-of-the-art equipment and software to ensure each audio element is perfectly balanced and polished, enhancing clarity, depth, and spatial balance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Why is mixing important in music production?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Mixing is crucial as it creates a cohesive sound, ensuring that each element of the track is heard clearly and works together harmoniously.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>Can I provide input during the mixing process?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we work closely with you to understand your creative vision and provide revisions and feedback to ensure the final mix aligns with your expectations.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How long does the mixing process take?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The duration of the mixing process varies depending on the complexity of the project and the number of revisions required. We strive to deliver high-quality mixes promptly.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>What genres do you specialize in?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We specialize in a wide range of genres, including pop, rock, hip-hop, electronic, and more, ensuring that your music receives the attention and expertise it deserves.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
