import React from "react";
import { Helmet } from "react-helmet";
import growth_icon from "../../../images/igg.png";
import ContactButton from "../../ContactButton";
import Particless from "./Growth_Particles";
import Testimonals from "../Testimonals";
import Staffing_FAQ from "./Staffing_FAQ";
import Growth from "../../../videos/staffing.gif";

const Staffing = () => {
  return (
    <div className="">

      {/* SEO Metadata */}
      <Helmet>
        <title>Staffing Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte provides expert Staffing services to help you find top talent for temporary, permanent, and contract roles. We ensure a perfect fit for your company’s culture and objectives."
        />
        <meta
          name="keywords"
          content="Staffing services, recruitment, temporary staffing, contract placements, permanent placements, talent sourcing, Ironglass Byte"
        />
        <meta property="og:title" content="Staffing Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Connect with top-tier talent through Ironglass Byte's comprehensive Staffing services. We handle sourcing, screening, interviewing, and onboarding for your staffing needs."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Staffing"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Growth_icon_stylized_v003.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Staffing" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl text-[#C0C0C0] mb-5 flex p-2 lg:p-4 m-2 font-bold ">
              Staffing
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#3bb24d] rounded-xl bg-black">
                At Ironglass Growth, our Staffing service connects you with top-tier
                talent to meet your business needs. We specialize in recruiting and
                placing qualified candidates in various roles, ensuring a perfect
                fit for your company culture and objectives. Our comprehensive
                staffing solutions include temporary, permanent, and contract
                placements, tailored to your specific requirements. We handle the
                entire recruitment process, from sourcing and screening to
                interviewing and onboarding, saving you time and resources.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={growth_icon}
                alt="Ironglass Growth Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={Growth} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#3bb24d] rounded-xl p-2 lg:p-4 m-2">
                Our commitment to excellence in staffing means we focus on quality
                and efficiency, delivering candidates who are not only skilled but
                also aligned with your company’s values and goals. We utilize
                advanced recruitment technologies and a vast network of
                professionals to find the best talent in the market. With Ironglass
                Growth, you can build a strong, capable team that drives your
                business forward. Our staffing solutions are designed to help you
                achieve operational success and long-term growth.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#C0C0C0]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Staffing_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#C0C0C0]">
            Contact us to meet your Staffing needs with the best talent!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staffing;
