import React, { useState, useEffect } from "react";
import vig from "../../../images/Untitled design (52).png";
import aether from "../../../images/Untitled design (51).png";
import dapps from "../../../images/Untitled design (50).png";
import audio from "../../../images/Untitled design (48).png";
import hera from "../../../images/Untitled design (49).png";
import celo from "../../../images/Untitled design (53).png";

const bubbles = [
  {
    showcaseImg: vig,
    color: "#0380ca",
    description: "6G Broadcast",
    showcaseContent:
      "6G technology enhancing communication networks ATSC3.0 CompliantBroadcasting application with blockchain redundancy.Explore the latest developments in 6G networks and how VIG is transforming global communications.",
  },
  {
    showcaseImg: hera,
    color: "#0380ca",
    description: "Hera",
    showcaseContent:
      "Blockchain Based Influencer Marketing and Social Media Content Distribution Application!",
  },
  {
    showcaseImg: aether,
    color: "#0380ca",
    description: "Aether Pantheon",
    showcaseContent:
      "AI Content generation & Distribution web/mobile application with lip sync capabilities & influencer matchmaking algorithm!",
  },
  {
    showcaseImg: dapps,
    color: "#0380ca",
    description: "Ironglass PoW Blockchain",
    showcaseContent:
      "Custom proof of work blockchain with native cryptocurrency,communicating nodes and smart contract deployment capabilities.",
  },
  {
    showcaseImg: celo,
    color: "#0380ca",
    description: "Celo",
    showcaseContent:
      "NFT Launch, Blockchain Based Staking Game, Blockchain E-commerce and Custom Token Launch!",
  },
  {
    showcaseImg: audio,
    color: "#0380ca",
    description: "Musiswap & Blockchain Power",
    showcaseContent:
      "Music token decentralized exchange and tokenized solar data decentralized exchange!.Audio production revolutionizing sound and media experiences.",
  },
];

const Soft_img_slides = () => {
  const [currentBubble, setCurrentBubble] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBubble((prevBubble) => (prevBubble + 1) % bubbles.length);
    }, 5000); // Change bubble every 5 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="showcase-section flex flex-col md:flex-row border border-[#0380ca] items-center p-5 w-full max-w-6xl mx-auto bg-[#121212]">
      <img
        src={bubbles[currentBubble].showcaseImg}
        alt="Showcase"
        className="showcase-img w-full md:w-1/2 card h-auto rounded-lg shadow-lg mb-5 md:mb-0"
      />
      <div className="text-content w-full md:w-1/2">
        <div
          className="description"
          style={{
            color: bubbles[currentBubble].color,
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        >
          {bubbles[currentBubble].description}
        </div>

        <p className="showcase-content text-lg mt-4 card text-gray-300">
          {bubbles[currentBubble].showcaseContent}
        </p>
      </div>
    </div>
  );
};

export default Soft_img_slides;
