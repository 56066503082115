export const APP_STATIC_PATH = {
  Main: "/",
  Dashboard: "/dashboard",
  Calendly: "/calendly",
  Members: "/members",
  Profile: "/profile",
  Dapps: "/Dapps",
  Apps: "/Apps",
  Cyber_Security: "/Cyber_Security",
  Hardware_Prototype: "/Hardware_Prototype",
  ArtificialIntelligence: "/ArtificialIntelligence",
  SmartContracts: "/SmartContracts",
  UIUX: "/UIUX",
  Websites: "/Websites",
  Apps: "Apps",
  ArtificialIntelligence: "ArtificialIntelligence",
  Careers: "/Careers",
  SmartAudit: "/SmartContractAuditing",
  Events: "/events",
  Login: "/login",
  Signup: "/Signup",
  Services: "/Services",
  DashPage: "/DashPage",
  Finance_page:"/Finance_page",
  Audio_page: "/Audio_page",
  Investment_page: "/Investment_page",
  Software_page: "/Software_page",
  Legal_page: "/Legal_page",
  Growth_page: "/Growth_page",
  Documents: "/Documents",
  AdminUpload: "/AdminUpload/:id",
  AdminOverview: "/AdminOverview",

  // sub servies
  Growth_overview: "/Growth_overview",
  Software_overview: "/Software_overview",
  Production_Overview: "/Production_Overview",
  Legal_Overview: "/Legal_Overview",
  Investment_Overview: "/Investment_Overview",
  Finance_Overview: "/Finance_Overview",

  //Growth
  Sales: "/Sales",
  SEO: "/SEO",
  Staffing: "/Staffing",
  AD_Marketing: "/AD_Marketing",
  Content_Marketing: "/Content_Marketing",
  Hr_Pay: "/Hr_Pay",

  //Law 
  Litigations:"/Litigations",
  Legal_Documents:"/Legal_Documents",
  Discovery:"/Discovery",
  Entity_Setup:"/Entity_Setup",
  Patents:"/Patents",

  //Production
  Mixing:"/Mixing",
  Mastering:"/Mastering",
  Production:"/Production",

  //Finance_page
  Taxes:"/Taxes",
  Accounts:"/Accounts",


  // payment
  Payments:"/Payments"
};
