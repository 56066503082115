import React, { useEffect, useRef, useState } from 'react';
import { payments } from '@square/web-sdk';  // Corrected import (lowercase 'payments')

const SquarePaymentForm = () => {
  const [paymentInstance, setPaymentInstance] = useState(null);
  const cardRef = useRef();

  useEffect(() => {
    async function initializePaymentForm() {
      try {
        const squarePayments = payments('YOUR_SQUARE_APPLICATION_ID', 'YOUR_LOCATION_ID');  // Make sure this is 'payments'
        const card = await squarePayments.card();
        await card.attach(cardRef.current);

        setPaymentInstance(squarePayments);
      } catch (error) {
        console.error('Failed to initialize Square payment form:', error);
      }
    }

    initializePaymentForm();
  }, []);

  const handlePayment = async (event) => {
    event.preventDefault();

    if (!paymentInstance) return;

    try {
      const paymentResult = await paymentInstance.card().tokenize();
      if (paymentResult.status === 'OK') {
        console.log('Payment token created:', paymentResult.token);

        // Send this token to your backend to process the payment
      } else {
        console.error('Payment failed:', paymentResult);
      }
    } catch (error) {
      console.error('Error handling payment:', error);
    }
  };

  return (
    <div className='text-white'>
      <h2>Payment Form</h2>
      <form onSubmit={handlePayment}>
        <div ref={cardRef} id="card-container" style={{ minHeight: '200px', border: '1px solid black', padding: '10px' }}>
          {/* Square Card Element will be inserted here */}
        </div>
        <button type="submit" disabled={!paymentInstance}>Pay</button>
      </form>
    </div>
  );
};

export default SquarePaymentForm;
