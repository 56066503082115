import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function HR_Payrolls_FAQ() {
  return (
    <div className='p-10 text-4xl'>
     <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #3bb24d; /* Bronze color */
            border: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #C0C0C0; /* Bronze color */
            border-bottom: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #3bb24d; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What HR Payroll services do you offer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We offer comprehensive payroll solutions, including payroll processing, tax filing, benefits administration, and more, tailored to your business needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How do you ensure compliance with payroll regulations?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our team stays updated with the latest laws and regulations to ensure your payroll operations remain compliant and efficient.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Can you handle multi-state payroll?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we have the expertise to manage payroll across multiple states, ensuring compliance with various state regulations.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What payroll software do you use?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We use advanced payroll software that ensures accurate and timely payroll processing, with features for reporting, tax filing, and employee self-service.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How do you handle payroll errors?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We have robust processes in place to minimize errors, and if any issues arise, our team acts swiftly to resolve them and ensure accurate payroll.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Do you provide payroll reporting?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we provide detailed payroll reports to help you manage your business finances effectively and ensure transparency.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
