import React from "react";
import { Helmet } from "react-helmet";
import growth_icon from "../../../images/igg.png";
import ContactButton from "../../ContactButton";
import Particless from "./Growth_Particles";
import Testimonals from "../Testimonals";
import Sales_FAQ from "./Sales_FAQ";
import Growth from "../../../videos/sales.gif";

const Sales = () => {
  return (
    <div className="">
      {/* SEO Metadata */}
      <Helmet>
        <title>Sales Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte's Sales services are designed to optimize your business's revenue and improve market presence. Our sales experts provide customized strategies, training, and tools to boost conversion rates and drive growth."
        />
        <meta
          name="keywords"
          content="Sales services, sales strategies, lead generation, conversion optimization, sales training, Ironglass Byte, business growth"
        />
        <meta property="og:title" content="Sales Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Boost your business's sales with Ironglass Byte's expert sales strategies, training, and tools. Our customized solutions help you identify and convert your ideal customers."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Sales"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Growth_icon_stylized_v003.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Sales" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl text-[#C0C0C0] mb-5 flex p-2 lg:p-4 m-2 font-bold ">
              Sales
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#3bb24d] rounded-xl bg-black">
                At Ironglass Growth, our Sales service is designed to enhance your
                business’s revenue and market presence. We provide customized sales
                strategies that align with your business goals, helping you identify
                and target your ideal customers. Our team of sales experts uses
                data-driven techniques and the latest industry insights to optimize
                your sales processes and improve conversion rates. By implementing
                effective sales training and coaching, we empower your team to close
                deals more efficiently and build long-lasting customer relationships.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={growth_icon}
                alt="Ironglass Growth Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img className="w-full h-auto" src={Growth} autoPlay muted loop playsInline />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#3bb24d] rounded-xl p-2 lg:p-4 m-2">
                Our approach to sales is holistic and comprehensive, covering every
                aspect from lead generation to customer retention. We analyze your
                current sales performance, identify areas for improvement, and
                develop actionable plans to boost your sales figures. With Ironglass
                Growth, you can expect a significant increase in your sales
                productivity and overall business growth. Our goal is to equip your
                sales team with the tools and knowledge they need to excel in
                today’s competitive market.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#C0C0C0]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Sales_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#C0C0C0]">
            Contact us to skyrocket your Sales performance!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
