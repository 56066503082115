import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SimpleAccordion() {
  return (
    <div><style>
    {`
      .MuiAccordion-root {
        background-color: black;
        color: #34b2fd; /* Blue color */
        border: 1px solid #34b2fd; /* Blue border */
      }
      .MuiAccordionSummary-root {
        background-color: black;
        color: #34b2fd; /* Blue color */
        border-bottom: 1px solid #34b2fd; /* Blue border */
      }
      .MuiAccordionDetails-root {
        background-color: black;
        color: #9ca3af; /* Blue color */
      }
      .MuiSvgIcon-root {
        color: #34b2fd; /* Blue color */
      }
    `}
  </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Decentralization</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Dapps operate on EVM compatible chains which are open decentralized public platforms where no one person or group has control.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Trustlessness</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Smart contracts can be analyzed and are guaranteed to execute in predictable ways, without the need to trust a central authority. This is not true in traditional models; for example, when we use online banking systems, we must trust that financial institutions will not misuse our financial data, tamper with records, or get hacked.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Privacy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          you don’t need to provide real-world identity to deploy or interact with a dapp users login using crypto wallets
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Censorship Resistant</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          No single entity on the network can block users from submitting transactions, interacting dapps, or reading data from the blockchain. Once the Dapp is deployed it will run forever.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Data Integrity</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Using cryptographic methods data stored on the blockchain is immutable and indisputable. Malicious actors cannot forge transactions or other data that has already been made public and stored on chain.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}