import React from "react";
import { Helmet } from "react-helmet";
import Accordion from "./Accordion2";
import ContactButton from "./ContactButton";
import Testimonals from "./pages/Testimonals.js";
import igb from "../images/igb.png";
import Particless from "./Particless.js";
import Soft_img_slides from "./pages/software/Soft_img_slides.js";
import byte from "../videos/dapp.gif";

function Events() {
  return (
    <div className=" ">
      <Helmet>
        <title>Blockchain Solutions - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers innovative Blockchain services, including smart contract development, dApp creation, and blockchain integration to transform business operations. Secure, transparent, and efficient solutions for various industries."
        />
        <meta
          name="keywords"
          content="Blockchain solutions, smart contract development, decentralized applications, dApp creation, blockchain integration, Ironglass Byte"
        />
        <meta property="og:title" content="Blockchain Solutions | Ironglass Byte" />
        <meta
          property="og:description"
          content="Transform your business with Ironglass Byte's blockchain solutions, including smart contract development, decentralized application creation, and blockchain integration for various industries."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Blockchain"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/IronGlass_Byte-logo.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Blockchain" />
      </Helmet>

      <Particless />

      <div className="flex  flex-col w-screen lg:flex-row min-w-full place-content-center mt-[15rem] lg:mt-10 px-2 lg:px-0">
        <div className="block mt-[-15rem]  mb-8">
          <div className="p-2 mt-[-15rem] lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl mb-5 flex text-blue2  p-2 lg:p-4 m-2 font-bold">
              Blockchain
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#34b2fd]">
                Ironglass Byte provides innovative Blockchain solutions that
                transform your business operations through secure and
                transparent decentralized systems. Our blockchain services
                include smart contract development, decentralized application
                (dApp) creation, and blockchain integration for various
                industries. We leverage the power of blockchain technology to
                enhance security, reduce costs, and streamline processes.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={igb}
                alt="Ironglass Byte Logo"
              />
            </div>
          </div>

          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:mb-0 lg:w-[100rem]">
              <img
                className="w-full h-auto"
                src={byte}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card bg-black border border-[#34b2fd] rounded-xl p-2 lg:p-4 m-2">
                Our team of blockchain experts is adept at developing custom
                blockchain solutions tailored to your specific business needs.
                Whether you are looking to implement a new blockchain system or
                integrate blockchain into your existing infrastructure, we
                provide end-to-end support. At Ironglass Byte, we are committed
                to helping you harness the potential of blockchain technology to
                drive innovation and growth.
              </p>
            </div>
          </div>

          <div className="text-center lg:h-auto text-xl lg:text-center header p-2 lg:p-4 md:p-0 mb-6">
            <h1 className="text-center text-blue2 font-extrabold m-2 p-2 text-4xl lg:text-5xl">
              Our Work Profiles
            </h1>
            
              <Soft_img_slides />
          </div>

          <Testimonals />

          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl">
              Frequently Asked Questions By Our Clients
            </h1>
            <Accordion />
            <br />
            <br />
          </div>

          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl">
          Contact us to implement Blockchain solutions today!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
