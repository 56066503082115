import React from "react";
import { Helmet } from "react-helmet";
import ContactButton from "../../ContactButton";
import Testimonals from "../../pages/Testimonals.js";
import "../../pages.css";
import Law from "../../../images/igl.png";
import Particless from "./Law_Particles.js";
import Patents_FAQ from "./Patents_FAQ.js";
import law from "../../../videos/patent.gif";

const Patents = () => {
  return (
    <div className="">

      {/* SEO Metadata */}
      <Helmet>
        <title>Patent Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte provides comprehensive Patent services, guiding you through the patent process from consultation to filing and enforcement. Protect your intellectual property with us."
        />
        <meta
          name="keywords"
          content="Patent services, patent law, intellectual property, patent filing, patent protection, patent portfolio management, Ironglass Byte"
        />
        <meta property="og:title" content="Patent Services | Ironglass Byte" />
        <meta
          property="og:description"
          content="Secure your intellectual property with Ironglass Byte's expert Patent services. We handle patent applications, portfolio management, and enforcement."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Patents"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_Law_icon_stylized_v004.png"
        />
        <link rel="canonical" href="https://www.ironglassbyte.com/Patents" />
      </Helmet>

      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl flex mb-5 text-[#9c682b] p-2 lg:p-4 m-2 font-bold ">
              Patents
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#9c682b] rounded-xl bg-black">
                At Ironglass Law, our Patent service is designed to protect your
                innovative ideas and intellectual property. We assist clients
                through every step of the patent process, from initial
                consultation and patent searches to drafting and filing patent
                applications. Our team of experienced patent attorneys ensures
                that your inventions are thoroughly documented and meet all
                legal requirements for patentability. We also provide strategic
                advice on how to leverage your patents to maximize their value
                and protect your competitive edge.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={Law}
                alt="Ironglass Law Logo"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem]">
              <img
                className="w-full h-auto"
                src={law}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card border border-[#9c682b] rounded-xl bg-black p-2 lg:p-4 m-2">
                Our commitment to excellence in patent law means that we stay
                abreast of the latest developments in technology and
                intellectual property rights. We offer comprehensive support,
                including patent portfolio management and enforcement of patent
                rights against infringement. Our attorneys are skilled in
                navigating the complexities of patent law, ensuring that your
                innovations receive the protection they deserve.
              </p>
            </div>
          </div>

          {/* Testimonials */}
          <Testimonals />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl text-[#9c682b]">
              Frequently Asked Questions By Our Clients
            </h1>
            <Patents_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl text-[#9c682b]">
            Contact us to secure your Patents with confidence!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patents;
