import React from "react";
import Finance from "../../../images/igf.png";
import ContactButton from "../../ContactButton";
import Particless from "./Finance_particles";
import Accounts_FAQ from "./Accounts_FAQ";
import fin_video from "../../../videos/account.gif";
import img from "../../../images/fin_pv.mp4";
import fin1 from "../../../images/Untitled design (54).png";
import fin2 from "../../../images/Untitled design (55).png";
import FinanceTestimonials from "../Testimonals.js";
import { Helmet } from "react-helmet";

const Accounts = () => {
  return (
    <div className="">
      {/* SEO Metadata */}
      <Helmet>
        <title>Accounts Services - Ironglass Byte</title>
        <meta
          name="description"
          content="Ironglass Byte offers expert Accounts services, including bookkeeping, financial statement preparation, payroll, and more for businesses of all sizes."
        />
        <meta
          name="keywords"
          content="Accounts, bookkeeping, financial statements, payroll, Ironglass Byte, finance management, accounts receivable, accounts payable"
        />
        <meta
          property="og:title"
          content="Accounts Services | Ironglass Byte"
        />
        <meta
          property="og:description"
          content="Expert Accounts services offered by Ironglass Byte. Manage your financial health with precision and reliability."
        />
        <meta
          property="og:url"
          content="https://www.ironglassbyte.com/Accounts"
        />
        <meta
          property="og:image"
          content="https://www.ironglassbyte.com/images/Ironglass_finance_icon_stylized_v004.png"
        />
      </Helmet>

      {/* Particle Background */}
      <Particless />

      {/* Main Container */}
      <div className="flex flex-col w-screen lg:flex-row min-w-full place-content-center mt-2 lg:mt-10 px-2 lg:px-0">
        {/* Top Content */}
        <div className="block mb-8">
          <div className="p-2 lg:p-12 m-2 lg:m-5 mb-4 lg:mb-10">
            <h1 className="text-4xl lg:text-6xl flex mb-5 text-[#F4C32C] p-2 lg:p-4 m-2 font-bold ">
              Accounts
            </h1>
            <div className="flex flex-col lg:flex-row">
              <p className="text-xl lg:text-2xl card p-2 m-2 lg:m-4 border border-[#F8F9FA]">
                Ironglass Finance offers comprehensive Accounts services to help
                you manage your finances with precision and efficiency. Our
                services include bookkeeping, financial statement preparation,
                accounts payable and receivable management, and payroll
                processing. We utilize advanced accounting software to ensure
                accuracy and provide you with real-time financial insights.
              </p>
              <img
                className="h-60 lg:h-[31rem] p-2 lg:p-5 rounded-lg z-10"
                src={Finance}
                alt="Ironglass Finance Icon"
              />
            </div>
          </div>

          {/* Video and Description Section */}
          <div className="p-2 lg:p-8 m-2 flex flex-col lg:flex-row gap-2">
            <div className="card bg-black p-2 lg:w-[100rem] ">
              <img
                className="w-full h-auto"
                src={fin_video}
                autoPlay
                muted
                loop
                playsInline
              />
            </div>

            <div className="w-full lg:w-[100%]">
              <p className="text-xl lg:text-2xl card  border border-[#F8F9FA] rounded-xl p-2 lg:p-4 m-2">
                Our team of experienced accountants works closely with you to
                understand your business needs and tailor our services
                accordingly. Whether you are a small business or a large
                corporation, we provide scalable solutions that support your
                financial operations. At Ironglass Finance, we are committed to
                helping you maintain financial health and achieve long-term
                success through meticulous and reliable accounting practices.
              </p>
            </div>
          </div>

          {/* Expertise Headline */}
          <div className="text-center lg:h-auto text-xl lg:text-center header p-2 lg:p-4 md:p-0 mb-6">
            <h1 className="text-center text-[#F4C32C] font-extrabold m-2 p-2 text-xl lg:text-4xl">
              Expert Account Management for Business Success
            </h1>
          </div>

          {/* Images Section */}
          <div className="flex justify-center">
            <div className="p-6">
              <img
                src={fin1}
                className="w-[40rem] card m-2"
                alt="Finance Image 1"
              />
            </div>

            {/* <div className="card ">
              <video
                className="w-[70rem]"
                src={img}
                autoPlay
                muted
                loop
                playsInline
              />
            </div> */}

            <div className="p-6">
              <img
                src={fin2}
                className="w-[40rem] m-2 p-6 card"
                alt="Finance Image 2"
              />
            </div>
          </div>

          {/* Testimonials */}
          <FinanceTestimonials />

          {/* FAQ Section */}
          <div>
            <h1 className="text-center font-extrabold m-2 p-2 text-2xl lg:text-3xl">
              Frequently Asked Questions By Our Clients
            </h1>
            <Accounts_FAQ />
            <br />
            <br />
          </div>

          {/* Contact Section */}
          <br />
          <p className="text-center font-extrabold text-2xl lg:text-3xl">
            Contact us to manage your Accounts effectively today!
          </p>
          <br />
          <div className="mb-[-3rem]">
            <ContactButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
