

// import React, { useState } from "react";
// import { CSSTransition } from "react-transition-group";
// import logo from "../../images/chat-bot.png"; // Replace with the path to your chatbot logo
// import "./byte.css"; // Importing the CSS file

// import OpenAI from "openai";

// const GptSB = () => {
//   const [chatOpen, setChatOpen] = useState(false); // Track chat visibility
//   const [popupVisible, setPopupVisible] = useState(true); // Track visibility of the initial pop-up
//   const [messages, setMessages] = useState([
//     "Hi, Welcome to Ironglass Byte.",
//     "May I know your name?",
//   ]); // Initial welcome messages
//   const [userMessage, setUserMessage] = useState(""); // User's input message
//   const [response, setResponse] = useState(""); // OpenAI response state

//   const OPENAI_KEY = "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";

//   const openai = new OpenAI({
//     apiKey: OPENAI_KEY,
//     dangerouslyAllowBrowser: true,
//   });

//   const handleGptSrch = async (userMessage) => {
//     const gptQuery = `
//     You are now Byte chatbot of Ironglass Byte. Here's how you should respond to the user's input:

//     1. **About Ironglass Byte**:
//        - "Welcome to Ironglass Byte! We specialize in providing cutting-edge solutions across multiple sectors to help businesses grow, streamline their operations, and stay ahead of the curve. Whether it's web development, cybersecurity, artificial intelligence, or business growth strategies, we are here to make your project the best it can be."

//     2. **Services We Offer**:
//        - We provide a wide range of services, organized into sectors:
//          - **Growth**: Ad Marketing, Staffing, Content Marketing, Sales, HR Payrolls, SEO
//          - **Production**: Mixing, Mastering, Music Production
//          - **Software**: Blockchain, Cyber Security, Web Design, Artificial Intelligence, Apps, Hardware Prototype, UI/UX
//          - **Finance**: Accounts, Taxing
//          - **Legal**: Patents, Discovery, Entity Setup, Legal Documents, Litigations

//     3. **How to Contact Us**:
//        - "You can reach us directly via phone at (929) 445-6122 or email our CEO Jake Hitz at Jake.ceo@ironglassbyte.com. Additionally, you can visit our Contact page and fill out a quick form, and one of our experts will get back to you within 24-48 hours. You can also book a direct call with our team for a more personalized consultation."

//     4. **Leaders at Ironglass Byte**:
//        - Introduce our leaders: Jake Hitz, CEO, and Osaze Moore, CTO, both experienced professionals in the fields of software engineering, blockchain, and cybersecurity.

//     5. **Responding to Service Inquiries**:
//        - If the user asks about a specific service, respond with: 
//          "Yes, we provide top-tier [SERVICE NAME] solutions. You can learn more about this service on our Services page, or feel free to ask further details, and our experts will be happy to assist."

//     6. **Encouraging Client Engagement**:
//        - Encourage the user to contact Ironglass Byte via the contact page, phone, or email for any inquiries about our services or to book a consultation.
//        - If the user asks for more details about a particular service, provide information and suggest they check the specific service page for more data.

//     Now, respond to the user’s input: "${userMessage}" based on the instructions above.
//   `;

//     try {
//       const gptResults = await openai.chat.completions.create({
//         messages: [{ role: "user", content: gptQuery }],
//         model: "gpt-3.5-turbo",
//       });

//       if (gptResults && Array.isArray(gptResults.choices) && gptResults.choices.length > 0) {
//         const gptResponse = gptResults.choices[0].message.content;
//         setResponse(gptResponse);
//         setMessages((prevMessages) => [...prevMessages, gptResponse]);
//       } else {
//         console.error("Unexpected API response format:", gptResults);
//       }
//     } catch (error) {
//       console.error("Error fetching response from OpenAI:", error);
//     }
//   };

//   const handleMessageSubmit = (e) => {
//     if (e.key === "Enter" && userMessage.trim() !== "") {
//       setMessages((prevMessages) => [...prevMessages, userMessage]);

//       // Trigger OpenAI response based on the user's message
//       handleGptSrch(userMessage);

//       setUserMessage(""); // Clear input field
//     }
//   };

//   const handleSendClick = () => {
//     if (userMessage.trim() !== "") {
//       setMessages((prevMessages) => [...prevMessages, userMessage]);
//       handleGptSrch(userMessage); // Call the GPT search function
//       setUserMessage(""); // Clear input field
//     }
//   };

//   return (
//     <div className="chatbot-container">
//       {/* Initial Pop-up Message */}
//       {popupVisible && (
//         <div className="chatbot__popup">
//           <p className="chatbot__popup-text">Got any questions? I'm happy to help.</p>
//           <button className="chatbot__close-btn" onClick={() => setPopupVisible(false)}>✖</button>
//         </div>
//       )}

//       {/* Chatbot Icon */}
//       <div className={`chatbot__header ${chatOpen ? "chatbot__header--open" : ""}`} onClick={() => setChatOpen(!chatOpen)}>
//         <img src={logo} className="w-14 cursor-pointer" alt="Chatbot Icon" />
//       </div>

//       {/* Chat Window */}
//       <CSSTransition in={chatOpen} timeout={300} classNames="chatbot__message-window" unmountOnExit>
//         <div className="chatbot__message-window">
//           <ul className="chatbot__messages">
//             {messages.map((message, index) => (
//               <li key={index} className={index % 2 === 0 ? "is-ai" : "is-user"}>
//                 <p className="chatbot__message">{message}</p>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </CSSTransition>

//       {/* Input Field */}
//       <CSSTransition in={chatOpen} timeout={300} classNames="chatbot__entry" unmountOnExit>
//         <div className="chatbot__entry">
//           <input
//             type="text"
//             placeholder="Write a message..."
//             value={userMessage}
//             onChange={(e) => setUserMessage(e.target.value)}
//             onKeyPress={handleMessageSubmit}
//             className="chatbot__input bg-gray-500 bg-opacity-90 rounded-3xl text-black"
//           />
//           <button className="chatbot__send-btn" onClick={handleSendClick}>Send</button>
//         </div>
//       </CSSTransition>
//     </div>
//   );
// };

// export default GptSB;



import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import logo from "../../images/chat-bot.png"; // Replace with the path to your chatbot logo
import "./byte.css"; // Importing the CSS file

import OpenAI from "openai";

const GptSB = () => {
  const [chatOpen, setChatOpen] = useState(false); // Track chat visibility
  const [popupVisible, setPopupVisible] = useState(true); // Track visibility of the initial pop-up
  const [messages, setMessages] = useState([
    "Hi, Welcome to Ironglass Byte.",
    "May I know your name?",
  ]); // Initial welcome messages
  const [userMessage, setUserMessage] = useState(""); // User's input message
  const [response, setResponse] = useState(""); // OpenAI response state

  const OPENAI_KEY = "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";

  const openai = new OpenAI({
    apiKey: OPENAI_KEY,
    dangerouslyAllowBrowser: true,
  });

  const handleGptSrch = async (userMessage) => {
    const gptQuery = `
    You are now Byte chatbot of Ironglass Byte. Here's how you should respond to the user's input:

    1. **About Ironglass Byte**:
       - "Welcome to Ironglass Byte! We specialize in providing cutting-edge solutions across multiple sectors to help businesses grow, streamline their operations, and stay ahead of the curve. Whether it's web development, cybersecurity, artificial intelligence, or business growth strategies, we are here to make your project the best it can be."

    2. **Services We Offer**:
       - We provide a wide range of services, organized into sectors:
         - **Growth**: Ad Marketing, Staffing, Content Marketing, Sales, HR Payrolls, SEO
         - **Production**: Mixing, Mastering, Music Production
         - **Software**: Blockchain, Cyber Security, Web Design, Artificial Intelligence, Apps, Hardware Prototype, UI/UX
         - **Finance**: Accounts, Taxing
         - **Legal**: Patents, Discovery, Entity Setup, Legal Documents, Litigations

    3. **How to Contact Us**:
       - "You can reach us directly via phone at (929) 445-6122 or email our CEO Jake Hitz at Jake.ceo@ironglassbyte.com. Additionally, you can visit our Contact page and fill out a quick form, and one of our experts will get back to you within 24-48 hours. You can also book a direct call with our team for a more personalized consultation."

    4. **Leaders at Ironglass Byte**:
       - Introduce our leaders: Jake Hitz, CEO, and Osaze Moore, CTO, both experienced professionals in the fields of software engineering, blockchain, and cybersecurity.

    5. **Responding to Service Inquiries**:
       - If the user asks about a specific service, respond with: 
         "Yes, we provide top-tier [SERVICE NAME] solutions. You can learn more about this service on our Services page, or feel free to ask further details, and our experts will be happy to assist."

    6. **Encouraging Client Engagement**:
       - Encourage the user to contact Ironglass Byte via the contact page, phone, or email for any inquiries about our services or to book a consultation.
       - If the user asks for more details about a particular service, provide information and suggest they check the specific service page for more data.

    Now, respond to the user’s input: "${userMessage}" based on the instructions above.
  `;

    try {
      const gptResults = await openai.chat.completions.create({
        messages: [{ role: "user", content: gptQuery }],
        model: "gpt-3.5-turbo",
      });

      if (gptResults && Array.isArray(gptResults.choices) && gptResults.choices.length > 0) {
        const gptResponse = gptResults.choices[0].message.content;
        setResponse(gptResponse);
        setMessages((prevMessages) => [...prevMessages, gptResponse]);
      } else {
        console.error("Unexpected API response format:", gptResults);
      }
    } catch (error) {
      console.error("Error fetching response from OpenAI:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        "Apologies! We are currently experiencing technical difficulties at Ironglass Byte. Please feel free to contact our support team at support@ironglassbyte.com or call us at (929) 445-6122 for immediate assistance. We will get back to you as soon as possible.",
      ]);
    }
  };

  const handleMessageSubmit = (e) => {
    if (e.key === "Enter" && userMessage.trim() !== "") {
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      // Trigger OpenAI response based on the user's message
      handleGptSrch(userMessage);

      setUserMessage(""); // Clear input field
    }
  };

  const handleSendClick = () => {
    if (userMessage.trim() !== "") {
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      handleGptSrch(userMessage); // Call the GPT search function
      setUserMessage(""); // Clear input field
    }
  };

  return (
    <div className="chatbot-container">
      {/* Initial Pop-up Message */}
      {popupVisible && (
        <div className="chatbot__popup">
          <p className="chatbot__popup-text">Got any questions? I'm happy to help.</p>
          <button className="chatbot__close-btn" onClick={() => setPopupVisible(false)}>✖</button>
        </div>
      )}

      {/* Chatbot Icon */}
      <div className={`chatbot__header ${chatOpen ? "chatbot__header--open" : ""}`} onClick={() => setChatOpen(!chatOpen)}>
        <img src={logo} className="w-14 cursor-pointer" alt="Chatbot Icon" />
      </div>

      {/* Chat Window */}
      <CSSTransition in={chatOpen} timeout={300} classNames="chatbot__message-window" unmountOnExit>
        <div className="chatbot__message-window">
          <ul className="chatbot__messages">
            {messages.map((message, index) => (
              <li key={index} className={index % 2 === 0 ? "is-ai" : "is-user"}>
                <p className="chatbot__message">{message}</p>
              </li>
            ))}
          </ul>
        </div>
      </CSSTransition>

      {/* Input Field */}
      <CSSTransition in={chatOpen} timeout={300} classNames="chatbot__entry" unmountOnExit>
        <div className="chatbot__entry">
          <input
            type="text"
            placeholder="Write a message..."
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyPress={handleMessageSubmit}
            className="chatbot__input bg-gray-500 bg-opacity-90 rounded-3xl text-black"
          />
          <button className="chatbot__send-btn" onClick={handleSendClick}>Send</button>
        </div>
      </CSSTransition>
    </div>
  );
};

export default GptSB;
