import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SimpleAccordion() {
  return (
    <div className="  p-10">
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #34b2fd; /* Blue color */
            border: 1px solid #34b2fd; /* Blue border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #34b2fd; /* Blue color */
            border-bottom: 1px solid #34b2fd; /* Blue border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #9ca3af; /* Blue color */
          }
          .MuiSvgIcon-root {
            color: #34b2fd; /* Blue color */
          }
        `}
      </style>
   

      <Accordion className=" ">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Automate Repetitive Tasks</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            AI enables automation of routine monotonous tasks in areas such as
            data collection, data entry, customer focussed business, email
            responses, software testing, invoice generation, and many more. This
            allows Employees to focus on tasks which require human abilities.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Reduce Human Error:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            AI-enabled computers make zero errors. This is because good AI
            models are based on predictive analysis leaving no scope for errors.
            This feature Helps to save both time and resources and helps in
            achieving accurate and efficient results.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Zero Risk</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Another big advantage of AI is that humans can overcome many risks
            by letting AI robots do them for us. This also allows AI to provide
            accurate work with greater responsibility and a lower chance of
            wearing out easily.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>24x7 Availability</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            AI can work endlessly without breaks. They think much faster than
            humans and perform multiple tasks at a time with accurate results.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Faster Unbiased Decisions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Using AI alongside other technologies we can make machines take
            decisions faster than a human and carry out actions quicker. While
            taking a decision human will analyze many factors both emotionally
            and practically but AI-powered machine works on what it is
            programmed and delivers the results in a faster unbiased way.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
