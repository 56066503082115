import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import QuickContact from "./QuickContact";

export default function ContainedButtons() {
  return (
    <div className="card  ">
      <QuickContact />
      <Stack direction="column" alignItems="center">
        <Link to="/calendly">
          <Button variant="contained">Schedule a meeting</Button>
        </Link>
      </Stack>
    </div>
  );
}
