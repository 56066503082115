import React from "react";
import intro3 from "../videos/intro.gif";
import Myparticle from "./Particless.js";

export default (props) => {
  return (
    <div className="section mt-[-100px] ">
      <Myparticle />
      <div className="intro">
        <img classname="video" src={intro3} autoPlay muted />
      </div>
    </div>
  );
};
