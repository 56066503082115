import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Mastering_FAQ() {
  return (
    <div className='p-10 text-4xl'>
       <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #9c2525; /* Bronze color */
            border: 1px solid #9c2525; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #C0C0C0; /* Bronze color */
            border-bottom: 1px solid #9c2525; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #9c2525; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is audio mastering?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Audio mastering is the final step in audio production, enhancing the overall sound quality, ensuring consistency across all playback systems, and preparing the track for distribution.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How long does the mastering process take?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The mastering process typically takes a few hours per track, but the exact time can vary based on the complexity and specific needs of the project.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>What should I provide for mastering?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We require the final mix of your track in high-resolution format, along with any specific instructions or references you have for the final sound.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>Can mastering improve a poorly mixed track?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            While mastering can enhance a mix, it cannot fix all issues. A well-mixed track is essential for achieving the best results in mastering.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>Do you offer revisions on mastering?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we offer revisions to ensure you are completely satisfied with the final master. Our goal is to meet your artistic vision and technical requirements.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>What formats do you deliver the mastered tracks in?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We deliver mastered tracks in various formats, including WAV, MP3, and other high-resolution formats, suitable for different distribution channels.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
