import React from "react";
import { InlineWidget } from "react-calendly";
import Myparticle from "./Particless.js";
import QuickContact from "./QuickContact.js";
import Leadership from "./Leaders.js";

function Calendly() {
  return (
    <div className="section">
      <Myparticle />

      <div className="mt-[-4rem]">
        <QuickContact />
      </div>
      <div>
        <Leadership />
      </div>

      <div class="text-center p-4 md:p-0 mb-12 md:mb-0 md:flex md:h-auto text-xl w-screen lg:text-left header">
        <h1
          data-aos="fade-right"
          data-aos-duration="2500"
          className="md:ml-10 lg:mt-8 text-[#9ca3af] orbitron underline decoration-[#34b2fd] underline-offset-8"
        >
          Schedule a complimentary meeting with our professional team
        </h1>
      </div>

      <InlineWidget
        className="h-80"
        url="https://calendly.com/jake-hitz"
        id="widget"
        color="#00a2ff"
      />
    </div>
  );
}

export default Calendly;
