import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SEO_FAQ() {
  return (
    <div className='p-10 text-4xl'>
     <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #3bb24d; /* Bronze color */
            border: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #C0C0C0; /* Bronze color */
            border-bottom: 1px solid #3bb24d; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #3bb24d; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is SEO?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            SEO, or Search Engine Optimization, involves improving your website's visibility in search engine results to drive organic traffic.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How does Ironglass Growth enhance SEO?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We conduct thorough keyword research, on-page optimization, and link-building strategies to improve your search engine rankings.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>What are the benefits of SEO?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            SEO helps increase your website's visibility, attract more qualified leads, and improve your online presence and credibility.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>How long does it take to see results from SEO?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            SEO is a long-term strategy, and it can take several months to see significant improvements in search engine rankings and traffic.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>What tools do you use for SEO?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We use various SEO tools for keyword research, site analysis, backlink tracking, and performance monitoring to ensure optimal results.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography>Can SEO help with local search rankings?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, our SEO strategies include local SEO techniques to improve your visibility in local search results and attract local customers.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
