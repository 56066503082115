import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import growth from "../../images/gsub.png";

import "./button.css"; // Import the CSS file
import Particless from "./growth/Growth_Particles";

const Growth = () => {
  const [animatedButtons, setAnimatedButtons] = useState({});
  const navigate = useNavigate();

  const handleClick = (buttonId, to) => (evt) => {
    evt.preventDefault();
    setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: true }));
    setTimeout(() => {
      setAnimatedButtons((prevState) => ({ ...prevState, [buttonId]: false }));
      navigate(to); // Navigate to the new page after the animation
    }, 1000);
  };


  const renderButton = (to, imgSrc, buttonId, label) => (
    <Link
      to={to}
      className={`btn bubble1 mx-3 p-10 text-xl text-blue2 blue2-border hover-item-sm orbitron ${
        animatedButtons[buttonId] ? "animated" : ""
      }`}
      onClick={handleClick(buttonId, to)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Centers content horizontally
        justifyContent: "center", // Centers content vertically
        textAlign: "center", // Ensures text is centered
        textDecoration: "none",
      }}
    >
      <div style={{ textAlign: "center" }}> {/* Ensures text stays centered */}
        {/* Image rendering */}
        <img
          src={imgSrc}
          className="mt-4 h-44"
          alt={label}
          style={{ display: "block", margin: "0 auto" }} // Ensures image is centered
        />
        {/* Label rendering */}
        {label && (
          <h1 className="text-[#62fa79] text-xl mt-[-12px]">
            {label}
          </h1>
        )}
      </div>
    </Link>
  );
  
  
  // const renderButton = (to, imgSrc, imgClass, buttonId, label) => (
  //   <Link
  //     to={to}
  //     className={`btn bubble1 bg-black mx-3 p-8 text-green-600 border hover-item-sm orbitron ${
  //       animatedButtons[buttonId] ? "animated" : ""
  //     }`}
  //     onClick={handleClick(buttonId, to)}
  //     style={{
  //       display: "flex",
  //       flexDirection: "column",
  //       alignItems: "center",
  //       textDecoration: "none",
  //     }}
  //   >
      
  //    <div>
      
     
  //     <img src={imgSrc} className={`${imgClass} h-44 flex justify-center  mt-[-30px]`}  />{" "}
  //     {/* Adjust height here */}
  //     {label && (
  //       <h1 className="text-[#62fa79] text-xl mt-[-30px] ">
  //         {label}
  //       </h1>
  //     )}

  //     </div>
  //   </Link>
  // );

  return (
    <div className=" mt-[2rem] w-screen ">
      <Particless />
      <div className="">
        <div
          className="flex justify-center slide-right"
          style={{ gap: "2rem" }}
        >
          {renderButton("/Sales", growth, "sales", "Sales")}
          {renderButton(
            "/AD_Marketing",
            growth,
            "advertisement_marketing",
            "Advertisement Marketing"
          )}
          {renderButton("/SEO", growth, "seo", "SEO")}
        </div>

        <div
          className="flex justify-center slide-left"
          style={{ gap: "2rem", marginTop: "1rem" }}
        >
          {renderButton("/Staffing", growth, "staffing", "Staffing")}
          {renderButton(
            "/Content_Marketing",
            growth,
            "content_marketing",
            "Content Marketing"
          )}
          {renderButton(
            "/Hr_Pay",
            growth,
            "hr_payrolls",
            "H R pay rolls"
          )}
        </div>
      </div>
      <div className="h-36"></div>
    </div>
  );
};

export default Growth;
