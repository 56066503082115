import React,{useEffect} from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

function Chart({finaldata}) {
    const ex= [{name:'lol',value:30},{name:'lol2',value:30},{name:'lol3',value:40}];
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    };
    console.log('finaldata passed to chart',finaldata)
    var pieData = []
    function getPieData(arr){
        console.log('getpie called')
        for(let i = 0; i<finaldata.length;i++){
            let val = Math.trunc(finaldata[i].Holdings)
            console.log('val',val)
            let row = {name:finaldata[i].Asset,value:val}
            pieData.push(row)

        }
        console.log('piedata',pieData)
        return pieData
    }

    useEffect(()=>{
        getPieData(finaldata)
    },[pieData])
    return(
        <div className="w-100 bg-[#bccaff]">
 <ResponsiveContainer>
         <PieChart width={700} height={700}>
          <Pie data={ex} dataKey="name" outerRadius={250} fill="green" />
        </PieChart>
      </ResponsiveContainer>
      </div>



    );
}
    export default Chart;