import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Patents_FAQ() {
  return (
    <div>
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border: 1px solid #9ca3af; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border-bottom: 1px solid #9ca3af; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #C0C0C0; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #b48c5e; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is a Patent?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A patent is a legal document granted by the government that gives an
            inventor exclusive rights to make, use, and sell an invention for a
            specified period.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How can Ironglass Law assist with Patents?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Ironglass Law assists with the entire patent process, including
            patent searches, application preparation, filing, and responding to
            USPTO actions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>What types of inventions can be patented?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Inventions that can be patented include new and useful processes,
            machines, manufactures, compositions of matter, and improvements
            thereof.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>What is the process for obtaining a patent?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The process involves conducting a patent search, preparing and
            filing a patent application, and navigating the examination process
            with the USPTO.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How long does it take to get a patent?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The time frame for obtaining a patent varies, typically ranging from
            one to three years, depending on the complexity of the invention and
            USPTO backlog.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
