import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Litigations_FAQ() {
  return (
    <div>
      <style>
        {`
          .MuiAccordion-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionSummary-root {
            background-color: black;
            color: #b48c5e; /* Bronze color */
            border-bottom: 1px solid #C0C0C0; /* Bronze border */
          }
          .MuiAccordionDetails-root {
            background-color: black;
            color: #9ca3af; /* Gray color */
          }
          .MuiSvgIcon-root {
            color: #b48c5e; /* Bronze color */
          }
        `}
      </style>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What is Litigation?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Litigation is the process of resolving disputes through the court
            system, involving the preparation and presentation of a case before
            a judge or jury.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How does Ironglass Law approach Litigation?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Ironglass Law's litigation team develops strategic approaches
            tailored to each case, whether it involves negotiation, mediation,
            or trial.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>
            What types of cases does Ironglass Law handle?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We handle a wide range of legal disputes, including commercial,
            civil, and criminal cases, representing clients in various types of
            litigation.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography>
            What are the stages of the Litigation process?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The stages include filing a complaint, discovery, pre-trial motions,
            trial, and potentially appeal, each requiring careful preparation
            and strategic planning.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography>How long does the Litigation process take?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The duration of litigation varies widely depending on the complexity
            of the case, court schedules, and whether the case is settled or
            goes to trial.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
