import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { updateUser, getUserById } from "../actions/users"; // Ensure you have this function in your actions

const UploadForm = () => {
  const { id } = useParams(); // Get user ID from URL
  const [logo, setLogo] = useState(null);
  const [details, setDetails] = useState("");
  const [company, setCompany] = useState("");
  const [userMail, setuserMail] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await getUserById(id);
        console.log(res, "response user");
        if (res.data) {
          setDetails(res.data.serviceDetails || ""); // Assuming 'details' is the field you want to pre-fill
          setCompany(res.data.company || "");
          setuserMail(res.data.email || "");
        }
      } catch (err) {
        console.error("Error fetching user data", err);
      }
    };
    fetchUserData();
  }, [id]);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogo(file);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("document", logo);
      formData.append("serviceDetails", details);
      formData.append("id", id); // Pass the user ID
      let data = {
        serviceDetails: details,
        id,
      };
      console.log(details, "data");

      const res = await updateUser(data);
      console.log(res, "response");
      if (res.data) {
        alert("upload success");
      } else {
        alert("something went wrong");
      }
    } catch (err) {
      console.log(
        err,
        "err",
        err.response,
        err.response.data,
        err.response.data.message
      );
      alert("something went wrong");
    }
  };

  return (
    <div>
      <h1 className="text-3xl text-blue2  p-10 m-2 font-extrabold flex justify-center">
        Send Documents to {company}
      </h1>
      <form
        onSubmit={handleFormSubmit}
        className="bg-black shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            htmlFor="companyName"
            className="block text-gray-400 text-md font-bold mb-2"
          >
            Company Name:
          </label>
          <input
            type="text"
            id="companyName"
            value={company}
            className="shadow appearance-none border rounded w-full bg-gray-900 py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
            readOnly
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="userEnail"
            className="block text-gray-400 text-md font-bold mb-2"
          >
            User Email:
          </label>
          <input
            type="text"
            id="userEmail"
            value={userMail}
            className="shadow appearance-none border rounded w-full bg-gray-900 py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
            readOnly
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="logo"
            className="block text-gray-400 text-md font-bold mb-2"
          >
            Upload Documents:
          </label>
          <input
            type="file"
            id="logo"
            accept="image/*"
            onChange={handleLogoChange}
            required
            className="shadow appearance-none border rounded w-full bg-gray-900 py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-6">
          <label
            htmlFor="details"
            className="block text-gray-400 text-md font-bold mb-2"
          >
            Service Details:
          </label>
          <textarea
            id="details"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            required
            className="shadow appearance-none border rounded bg-gray-900 w-full py-2 px-3 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        
        <div className="flex justify-center ">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-100 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline box__link button-animation "
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default UploadForm;
