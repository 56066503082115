import React from "react";
import intro3 from "../videos/igb-home.gif";

export default (props) => {
  return (
    <div className="w-screen pb-36 mt-0 lg:mt-[-65px]">
      <div className="card">
        <img
          className="w-screen h-[70%] bg-black"
          src={intro3}
          autoPlay
          muted
          loop
          playsInline
        />
      </div>
    </div>
  );
};
